<template>
 <a-modal
  title="限量沽清"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div style="display: flex">
    <div class="leftone">
      <div>
        <a-table
          :columns="columns"
          :data-source="outArr"
          :rowKey="
            (record, index) => {
              return index;
            }"
          :pagination="{
            current:currentTa,
            total:totalTa,
            pageSize:8,
          }"
          @change="handleTableChange"
          :locale="{emptyText: '暂无数据'}"
        >
          <span slot="icon" slot-scope="text, item"  @click="clickDe(item)">
            <div class="delet" >
              <a-icon style="color: #ff3b30" type="close" />
            </div>
          </span>
          <p slot-scope="record" style="margin: 0">
            {{ record }}
          </p>
        </a-table>
      </div>
        <div class="myButton">
          <button class="btnTwo" @click="clickEmpty">清空限量沽清列表</button>
        </div>
     </div>
      <div class="rightone" >
        <div class="myInput">
          <a-input class="input" placeholder="请输入搜索内容"  v-model="goods_no"  @change="onSearch"/>  
        </div>
            <a-tabs default-active-key="0" type="card"  @change="callback">
                <a-tab-pane tab="全部商品"  key="0">
                <ul>
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="zhe"  v-if="item.is_stockout===2"></div>
                    <div class="myImg">
                      <img :src="item.preview_url" alt=""  v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods">
                      {{ item.goods_name }}
                    </div>
                    <div class="h5">
                      <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}</span>
                    </div>
                    <div class="replace-item" v-if="item.is_stockout===2">
                      <div class="jiao"></div>
                      <div class="desc">已售罄</div>
                    </div>
                     <div class="replace-item" v-if="item.limited_stock_total !=0.00">
                      <div class="jiao"></div>
                      <div class="desc">限量沽清</div>
                    </div>
                  </li>
                </ul>
              </a-tab-pane> 
               <a-tab-pane  v-for="(item) in tabList" :key="item.category_id" :tab="item.name" >
                <ul>
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="zhe"  v-if="item.is_stockout===2"></div>
                    <div class="myImg">
                      <img :src="item.preview_url" alt=""  v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods">
                      {{ item.goods_name }}
                    </div>
                    <div class="h5">
                      <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}</span>
                    </div>
                    <div class="replace-item" v-if="item.is_stockout===2">
                      <div class="jiao"></div>
                      <div class="desc">已售罄</div>
                    </div>
                    <div class="replace-item" v-if="item.limited_stock_total !=0.00">
                      <div class="jiao"></div>
                    <div class="desc">限量沽清</div>
                    </div>
                  </li>
                </ul>      
              </a-tab-pane>
            </a-tabs>    
            <div>             
            </div> 
            <div class="page">
               <a-pagination v-model="current" :total="total"  @change="changePage" :defaultPageSize="12"/>  
            </div>
                    
          </div>
        </div>
        <stockChoose :isShow="showChooseStock" @cancel="changeChoose" :data="everyGoods"/>
 </a-modal>
</template>
<script>
import {limitedStockoutList,clearLimitedStockout,limitedStockout} from '@/request/stock.js';
import {categoryList,goodsList} from '@/request/business.js';
import stockChoose from './stockChoose.vue';
const columns = [
  { title: "商品", dataIndex: "goods_name", key: "name" },
  { title: "可用库存数量", dataIndex: "limited_stock_total", key: "limited_stock_total" },
  { title: "", scopedSlots: { customRender: "icon" } },
];
export default {
  props:['isShow'],
  components: {
    stockChoose
  },
  data() {
    return {
      columns,
      goodsListArr: [], //商品列表
      outArr:[],//沽清列表
      everyGoods:'',//点击每条商品
      tabList:[],//商品分类
      goods_no:"",//商品搜索
      total:0,
      current:1,//当前页
      category_id:'',//商品分类
      currentTa:1,//表格当前页
      totalTa:0,//表格数据}
      showChooseStock:false,
    };
  },
  
  mounted(){ 
    this.goodsRequest();
    this.tabRequest();
    this.stockListRequest()
  },
  methods: {
    callback(key){ //点击tabs切换
      this.current=1;
      this.category_id=key;
      this.goodsRequest()
    },
    handleTableChange(e){ //点击分页
      this.currentTa = e.current;
      this.stockListRequest()
    },
    goodsRequest(){    //商品列表请求
      const message={
        page:this.current,
        category_id:  parseInt(this.category_id) ? parseInt(this.category_id): "",
        page_size:12,
        goods_name:this.goods_no,
      }   
      goodsList(message).then((res) => {
        if(res.data.status===200){
            const list =res.data.data.list && res.data.data.list;
            this.goodsListArr=list.data && list.data;
            this.total=list.total &&  list.total            
          }           
        }).catch(result => {
          return false;
        })
    },
    clickEveryGood(item){
      if(item && item.is_stockout ===2){
        this.$message.warn('该商品已售罄',1.5);
      }else if(item && item.is_stockout ===1){
        this.everyGoods=item;
        this.showChooseStock=true;
      }   
    },
    tabRequest(){  //商品分类请求
      categoryList().then((res) => {
        if(res.data.status===200){
          const list =res.data.data.list && res.data.data.list;
          this.tabList=list; 
        }           
      }).catch(result => {
        return false;
      })
    },
    onSearch(){ //商品列表搜索
      this.goodsRequest()
    },
    clickEmpty(){ //清空按钮
       this.stockoutRequest(0,2,2)
    },
    changePage(current){ //点击分页
      this.current = current;
      this.goodsRequest()
    },
    stockoutRequest(goods_id,type,number){ //商品盘点请求
      const _this=this;
        clearLimitedStockout({
          goods_id:goods_id,
          type:type,
          is_all:number,//是否全部清空
          })
        .then((res) => {
          if(res.data.status===200){
              _this.goodsRequest();
              _this.stockListRequest()
            }else{
               _this.$message.warn('沽清失败',1.5);
            }              
          })
        .catch(result => {
            return false;
      })
    },
    clickDe(item){ //点击删除按钮
        limitedStockout({
          goods_id:item&&item.goods_id,
          type:2,
          }).then((res) => {
          if(res.data.status===200){
              this.$message.success('删除成功',1.5);
              this.stockListRequest()
              this.goodsRequest()
            }             
          }).catch(result => {
            return false;
      })  
    },
    stockListRequest(){//沽清列表
       const _this=this;
        limitedStockoutList({
         page:_this.currentTa,
         listRows:10
          })
        .then((res) => {
          if(res.data.status===200){
           const list =res.data.data.list && res.data.data.list;
           _this.totalTa=res.data.data.list.total && res.data.data.list.total;
           _this.outArr=res.data.data.list.data && res.data.data.list.data;
            }             
          })
        .catch(result => {
            return false;
      })
    },
    handleSubmit(){

    },
    handleCancel(){
      this.$emit('cancle',false)
    },
    changeChoose(){
      this.showChooseStock=false;
      this.stockListRequest();
       this.goodsRequest()
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:88.6vw !important;
  height:80vh !important;
}
/deep/.ant-modal-content{
  height:80vh !important;
}
/deep/.ant-modal-header{
  background: #edeef2;
  height:6vh;
  line-height: 6vh;
  padding:0 24px;
  .ant-modal-title{
    font-weight: bold;
     line-height: 6vh;  
  }
}
/deep/.ant-modal-body{
  padding:0 1vw  0 1vw;
  background: #edeef2;
  border-radius:0 0 4px 4px;
  height:74vh;
}
.leftone {
  width: 29vw;
  background: #fff;
  margin-right: 1vw;
  position: relative;
  height:74vh !important;
}
.ant-table-wrapper {
    width: 29vw;
  }
.rightone {
    width: 60vw;
    background-color: #fff;
    position: relative;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin: 0vh 1vh !important;
  background: #fff;
  border-radius: 0.5vh 0.5vh 0.5vh 0.5vh;
  font-size: 1.8vh;
  text-align: center;
  line-height: 4.1vh;
  height: 4.1vh;
  color: black;
  border-color: rgba(166, 166, 166, 1);
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 7vh;
}
/deep/.ant-tabs-nav-container{
  line-height: 7vh;
}
/deep/.ant-tabs-bar{
  margin:0;
  width:38vw;
  border-bottom: 0 !important;
}
/deep/ul{
  margin-bottom: 0;
  margin-top:2vh;
  margin-left:1vw;
}
/deep/.ant-tabs-content{
  border-top: 1px solid #e8e8e8;
}
.myInput {
  position: absolute;
  right:5vw;
  top:1.5vh;
  z-index: 10;
}
.ant-input{
  border:1px solid rgba(166, 166, 166, 1);
}
/deep/.ant-modal-wrap{
  top:-6vh;
}
.page ul{
  margin-top:0.5vh;
  margin-bottom: 2vh;
} 
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
button {
    width: 28vw;
    height: 6vh;
    background-color: #ff6700;
    border: none;
    border-radius: 0.5vh;
    color: white;
    font-size: 1.8vh;
    display: inline-block;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    position: absolute;
    bottom:2vh;
    left:0.5vw;
  }



.replace-item {
    right: 0;
    top: 0;
    position: absolute;
    color: #ffffff;
    font-size: 12px;
    }
    .jiao, .desc {
    position: absolute;
    top:0;
    right: 0;
    }
  .jiao {
    width: 44px;
    height: 80px;
    transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);;
    top: -30px;
    right: -10px;
    background-color: #ff685d;
    padding:0;
  }
  .desc {
    transform: rotate(40deg);
    top: 8px;
    font-size: 12px;;
  }
  .delet{
    cursor: pointer;
  }
  .zhe{
    width:12.9vw;
    height:18.1vh;
    position: absolute;
    left: 0;
    top:0;
    background:#00000030;
  }
.right {
    width: 83vw;
    height: 95.4vh;
  }
 
  
  .input {
    width: 20vh;
    height: 4.1vh;
  }
  
  .flex {
    display: flex;
    justify-content: space-between;
  }
  /deep/.ant-table {
    font-size: 1.8vh;
  }
  /deep/.ant-table-thead {
    font-size: 1.8vh;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  h5 {
    color: #ff6700;
   
  }
  
  .goods {
    font-size: 1.8vh;
    width:100%;
    padding-left:0.5vw;
  }
  .h5 {
    display: flex;
    justify-content: space-between;
    font-size: 1.4vh;
    color: #ff6700;
     padding: 0 0.5vw;
  }
  
  
  li {
    width:12.9vw;
    height:18.1vh;
    margin: 0vh 1vh 1vh 0;
    border-radius: 0.5vh;
    background-color: #f8f8ff;
    cursor: pointer;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  li:hover {
    color: #ffffff;
    background: #ff6700;
    .h5 {
      color: #ffffff;
    }
  }
  img {
    width: 12.9vw;
    height: 12vh;
    padding:0.5vw 0.5vw 0.5vw 0.5vw;
  }
  .myImg {
    display: flex;
  }
  .btnTwo {
    background-color: #fff;
    color: black;
    // top: 93vh;
    border: 0.1vh solid #d0d0d0;
  }
  /deep/.ant-pagination-prev {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-pagination-next {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
  }
  /deep/.ant-pagination-item {
    min-width: 3vh;
    width: 3.6vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-table-pagination.ant-pagination {
    margin: 1vh 3vh;
  }
  
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #ff6700;
    color: white;
    border: 1px solid #ff6700;
  }
  .delet{
    cursor: pointer;
  }
  /deep/.ant-tabs-tab-active{
    border-bottom:1px solid  #ff6700 !important;
  }
  
  .page{
    overflow: hidden;
  }
  /deep/.ant-pagination{
    float: right;
    margin-right:4vw;
  }
 /deep/.ant-pagination-item-active {
        border-color: rgba(255, 103, 0, 1);
        a {
          color: rgba(255, 103, 0, 1);
        }
 }  
</style>
