<template>
  <div>
    <a-modal
      :visible="isShow"
      title="限量沽清"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-table :columns="columns"  bordered :data-source="datas"  :rowKey="(record,index)=>{return index}" :pagination="false">
          <span slot="operation" slot-scope="text">
              <span class="jian" @click="jian">-</span> <span class="fonunt"><input type="text" v-model="num"></span> <span class="jian" @click="jia">+</span>
          </span>
      </a-table>
      <div class="ji">
        <keyNum :parentNum="(this.num).toString()" @hidden_num="updateNum"/> 
      </div>  
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num.vue";
import {limitedStockout} from '@/request/stock.js';
export default { 
  props: ['isShow','data'],
  components: {keyNum},
  data() {
    return {
     columns:[
       {
        title: '商品名称',
        dataIndex: 'goods_name',
        width: '25%',
        align:"center"
       },
       {
        title: '原库存',
        dataIndex: 'stock_total',
        width: '25%',
        align:"center"
       },
       {
        title: '可用库存数量',
        dataIndex: '',
        width: '25%',
        align:"center",
        scopedSlots: { customRender: 'operation' },
       }, 
     ],
     datas:[],
      num:1, 
    };
  },
  watch:{
    data:{
      handler(){
       if(this.isShow){
         this.datas=[this.data];
         console.log(JSON.stringify( this.datas))
       }
      }
    }
  },
  updated(){
    
  },
  methods: { 
    jia(){
      this.num ++
    },
    jian(){
      if(this.num>1){this.num --}
    },
    updateNum(num){
      this.num = num;
    },
    handleOk() {  
        limitedStockout({
          goods_id:this.data && this.data.goods_id,
          limited_stock_total:this.num,
          type:1,
          })
        .then((res) => {
          if(res.data.status===200){
              this.$message.success('沽清成功',1.5);
              this.num=1;
              this.$emit("cancel", false); 
            }             
          })
        .catch(result => {
            return false;
      })  
    },
    handleCancel() {
      this.num=1;
      this.$emit("cancel", false);
    }, 

  },    
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:45vw !important;
}
/deep/.ant-modal-content{
    width:45vw;
    background: rgba(237, 238, 242, 1);
    position:relative;
}
/deep/.ant-form-item-label {
  margin-top: 3vh;
  font-size: 1.8vh;
}
 /deep/.ant-modal-header {
   background: rgba(237, 238, 242, 1);
}
  /deep/.ant-modal-footer {
  text-align: center;
}
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding-bottom: 0;
}
.jian{
  display: inline-block;
  width:4vh;
  height:4vh;
  line-height: 3.5vh;
  text-align: center;
  border:1px solid rgba(198, 198, 198, 1);
  font-size: 2.2vh;
  color:rgba(255, 102, 0, 1);
  font-weight: bold;
}
.fonunt{
  margin:0 0.4vw;
}
/deep/th{
  font-size: 1.8vh !important;
  font-weight: bold !important;
  background: rgba(198, 198, 198, 1) !important;
}
/deep/td{
  font-size: 1.8vh !important;
   background: #fff !important;
}
input{
  width:2vw !important;
  border: none;
}
input:focus{
  outline: none;
}
/deep/.ulJian{
  border-radius: 4px;
  li{
    width:33.3%;
    height: 7.5vh;
    line-height: 7.5vh;
    font-size: 2.2vh;
  }
}
/deep/.ant-modal-wrap{
  top:-6vh;
}
 /deep/.ant-modal-title{
    font-weight: bold;
  }
</style>