<template>
  <div>
    <a-modal
      :visible="isShow"
      title="请扫码"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleOk"
    >
      <div class="myDiv" @mousedown="stopClick">
        <div>
          <p><b v-if="icon !=2">￥{{ current_price }}</b> </p>
          <div class="input" v-if="icon !=2">
            <a-form-model :model="form" :rules="rules" ref="ruleForm">
              <a-form-model-item ref="price" prop="price" label="">
                <a-input class="input" v-model="auth_no" ref="input"></a-input>
              </a-form-model-item>
            </a-form-model>
          </div>
          <img src="@/assets/sao.png" alt="" v-if="icon === 0">
          <a-result :title='`收款成功:${current_price}元`' class="loading" v-if="icon === 2">
            <template #icon>
              <a-icon  type="smile" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
            </template>
          </a-result>
          <a-result title="支付失败!" v-if="icon === 3" class="loading" >
            <template #icon>
              <a-icon type="frown"  theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
            </template>
          </a-result>
           <div class="loading" v-if="icon === 1">
            <Loading />
          </div> 
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Loading from '@/components/payLoading/payLoading';
import { fastPayOrder,orderpaystatus} from "@/request/business";
export default {
  props: ["isShow","current_price"],
  components:{Loading},
  data() {
    return {
      form:{
        auth_no:'',
      },
      auth_no:'',
      rules: {//付款码
        auth_no: [
          { required: true, trigger: "blur", message: "请输大于0的数字"},
        ],
      },
      time: "", //接收定时器
      timeouts:'',
      canPay:true,
      oldValue:'',
      icon:0,
      paylog_id:"",
    };
  },

   watch: {
    auth_no(val){
      if(val){
        // if(this.form.price !=='' && (this.form.price.length==18 || this.form.price.length==19)){
        //        this.payRequest("扫码支付成功")
        //   }else if(this.form.price !=='' && this.form.price.length>19){
        //     this.$message.warn('请勿重复扫码!',1.5);
        //     this.auth_no =''
        //   } 
          this.canPayHandle(val)
      }
    },
    icon:{
      handler(){ 
        if(this.icon == 1){
          this.time=setInterval(() => {
              setTimeout( this.orderStatusRequest(this.paylog_id), 0)           
          }, 1000)
        }else{
          clearInterval(this.time);
        };
        if(this.icon==2 ){
            clearTimeout(this.timeouts)
            this.timeouts = setTimeout(() => {
              this.auth_no="";
              this.icon=0;
              this.handleOk()
          }, 1000) 
          }; 
        if( this.data_state===3){
          clearTimeout(this.timeouts)
          this.timeouts = setTimeout(() => {
          this.auth_no="";
          this.icon=0;
          this.handleOk()
          }, 1000)
        };   
      },
      deep:true
    },
    isShow:{
      handler(){
        this.$nextTick(() => {
          this.$refs.input.focus();
        })
      },
      deep:true,
    }
  },
  methods: {
    canPayHandle(value){
        if(this.canPay){
            if(this.oldValue == value){
                this.canPay = false
                this.orderRequest()
                setTimeout(() => {
                    this.canPay = true
                },200)
            }else{
                setTimeout(() => {
                    this.oldValue = value
                    this.canPayHandle()
                },100)
            }
        }
    },
    stopClick(event){
      event.preventDefault()
    },
    handleOk() {
      this.$emit("closeHandle", true); //关闭按钮
      this.auth_no=""
      this.$emit('clearPrice')
    },
    orderRequest(){ 
      const message={
        pay_type:'aggregatepay',  //付款方式
        auth_no:this.auth_no,
        current_price:this.current_price,  //付款金额
      }  
      fastPayOrder(message).then((res) => {
        if(res.data.status===200){
          this.paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
          this.orderStatusRequest(this.paylog_id);//请求获取支付状态     
        }                 
      }).catch(result => {
        return false;
      })
    },
    orderStatusRequest(id){   //余额结算获取支付状态
      orderpaystatus({paylog_id:id}).then((res) => {
        if(res.data.status===200){
          this.icon=res.data.data.status  && res.data.data.status;
          const speech_path= res.data.data.speech_path && res.data.data.speech_path;
          if(speech_path){
            bound.play(speech_path)
          }  
        }                   
        }).catch(result => {
          return false;
        })
    },
  },
 
};
</script>
<style lang="less" scoped>
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:10;
}
/deep/.ant-modal{
 width: 50vh !important;
}
/deep/.ant-modal-content {
  background-color: #edeef2;
  width: 50vh;
  position:relative;
}
/deep/.ant-modal-header {
  background-color: #edeef2;
}
/deep/.ant-modal-body {
  height:50vh;
  .myDiv {
    background: white;
    padding-bottom: 4vh;
    height:38vh;
    p {
      padding-top:3vh;
      font-size: 5vh;
      text-align: center;
    }
    .myImg {
      text-align: center;
      img {
        width: 12vh;
      }
    }
    .footer {
      .wechat {
        img {
          width: 4vh;
          margin-top: 8vh;
          margin-left: 3.5vh;
        }
        p {
          font-size: 2vh;
          margin-top: -3.5vh;
          margin-left: -23vh;
        }
      }
      .alipay {
        img {
          width: 4vh;
          margin-top: -10vh;
          margin-left: 29vh;
        }
        p {
          font-size: 2vh;
          margin-left: 30vh;
          margin-top: -7.5vh;
        }
      }
    }
  }
}
.input {
  padding:0 1vw;
  color:black;
}
/deep/.ant-input {
  height: 2vh;
  width:10vw;
  position: absolute;
  left:1vw;
  top:10vh;
  z-index: 1;
  border-color: white;
  background-color:rgba(237, 238, 242, 1) ;
  opacity: 0;
}
/deep/.ant-btn {
  width: 16.9vh;
  height: 5.15vh;
  font-size: 1.8vh;
}
/deep/.ant-btn-primary {
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  color: #fff;
  font-size: 1.8vh;
  padding: 0;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
/deep/.ant-result{
padding:0;
}
/deep/.ant-result-icon{
  margin-bottom:1vh !important;
}
img{
  width:40vh;
  height:20vh;
  position: relative;
  left:2vw;
  top:-3vh;
  height: 21vh;
  z-index: 2;
}
/deep/.anticon{
    font-size: 10vh !important;
  }
</style>
