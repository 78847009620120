<template>
    <div class="win">
        <!-- 寄存商品 -->
        <a-modal title="商品寄存" :visible="isShow" @cancel="handleCancel" width="58vw">
            <div class="top">
                <div class="left">
                    <a-input placeholder="请输入存放人姓名/手机号" @change="search" v-model="keywords"/>
                    <a-button class="smallOkBtn" @click="search">查询</a-button>
                </div>
                <div class="right">
                    <a-button class="smallOkBtn" @click="showAdd=true">存放</a-button>
                    <a-button class="smallOkBtn" @click="showPosition=true">存放位置</a-button>
                </div>
            </div>
            <div class="table">
                <a-table
                    :columns="columns"
                    :data-source="list"
                    bordered
                    :locale="{emptyText: '暂无数据'}"
                    :rowKey="(record, index) => {return index;}"
                    :pagination="{
                        current: current,
                        total: total,
                        pageSize: 7,
                    }"
                    @change="handleTableChange"
                >
                    <span slot="wine_user_id" slot-scope="text,record">
                        <span class="qv" @click="showRetireve=true;wine_user_id=text;name=record.name">取回</span>
                    </span>
                </a-table>
            </div>
        </a-modal>
        <addModel :isShow="showAdd"  @closeHandle="showAdd=false" @updateList="updateList"/>
        <positionModel :isShow="showPosition" @closeHandle="showPosition=false"/>
        <retireveModel :isShow="showRetireve" @closeHandle="showRetireve=false" @updateList="updateList" :wine_user_id="wine_user_id" :name="name"/>
    </div>
</template>
<script>
import addModel from './add.vue'
import positionModel from './position.vue'
import { getWineUserList} from "@/request/business";
import retireveModel from './retrieve.vue'
export default {
    name:'orderRemark',
    props:['isShow'], 
    components:{addModel,positionModel,retireveModel}, 
    data(){
        return{
            columns:[
                {title: "存放人姓名",dataIndex: "name"},
                {title: "手机号",dataIndex: "mobile"},
                {title: "操作",dataIndex: "wine_user_id", scopedSlots: {customRender: "wine_user_id"},},
            ],
            showAdd:false,
            showPosition:false,
            list:[],
            total:0,
            keywords:"",//筛选-手机号或姓名
            current: 1, //表格当前页
            showRetireve:false,
            wine_user_id:"",
            name:"",
        }
    },
    watch:{
        isShow(val){
            if(val){
              this.userList()
            }
        }
    },
    updated(){
    },
    methods:{
        updateList(){
            this.current=1
            this.userList()
        },
        userList(){
            getWineUserList({
                keywords:this.keywords,
                listRows:7,
                page:this.current
            }).then(res => {
                if(res.data.status === 200){
                    const list=res.data.data.list.data && res.data.data.list.data 
                    this.list=list
                    this.total=res.data.data.list.total
                    // this.handleCancel()
                }
            })
        },
        handleCancel(){
            this.current=1
            this.keywords=""
            this.$emit('closeHandle')
        },
        handleTableChange(e) {  //点击分页
            this.current = e.current;
            this.userList();
        },
        search(){
            this.current=1;
            this.userList()
        },
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal-content{
    background: #edeef2;
    .ant-modal-header{
       background: #edeef2; 
    }
    .ant-modal-body{
        padding:1.8vh 24px;
        .top{
            display: flex;
            justify-content: space-between;
            .left{
                display: flex;
                .ant-input{
                width:23vw;
                height: 4.6vh; 
                border-radius: 2.3vh;
                }
                .smallOkBtn{
                    height:4.6vh;
                    margin-left: 1.3vw;
                    width:7vw;
                }
            }
            .right{
                display: flex;
                .smallOkBtn{
                    height:4.6vh;
                    margin-left: 1.3vw;
                     width:7vw;
                }
            }
        }
        .table{
            margin-top: 2vh;
            th{
                background: #edeef2; 
                font-weight: bold;
                font-size: 1.8vh;
                padding:10px 10px;
                text-align: center;
            }
            td{
                background: #fff;
                font-size: 1.8vh;
                padding:10px 10px;
                text-align: center;
            }
            .qv{
                color:#ff6600;
                cursor: pointer;
            }
        }
    }
    .ant-modal-footer{
        display: none;
    }
}
</style>