<template>
<div class="cent_left">
    <ul>
        <li class="li" v-for="(item, keys) in tableListArr" :key="keys" @click="orderDetail(item, keys);showFoundings(item)" 
        :class="{
          founding_is_order: 1 === item.is_founding,
          is_select: action_table_info.id == item.id,
        }"
        >
            <div v-if="item.is_recently_order == 1" class="yuding">
                <span class="yu">预</span>
            </div>
            <div class="tablename">{{ item.table_name }}</div>
            <div class="createtime" v-if="item.is_founding">￥{{ item.price }}</div>
            <div class="people">
                <span v-if="item.is_founding">{{ item.founding_time }}</span>
                <span v-else></span>
                <span v-if="item.is_founding">{{ item.people }}/{{ item.max_people }}人</span>
                <span v-if="!item.is_founding">0/{{ item.max_people }}人</span>
            </div>
        </li>

        <div class="page">
            <a-button type="primary" @click="upPage">上一页</a-button>
            <a-button type="primary" @click="nextPage">下一页</a-button>
        </div>
    </ul>
    <!-- 开台 -->
    <Founding :data-item="check_item" :reserveData="routeData" @hiddenFounding="updateFounding" v-if="foundingVisible" :founding-visible="foundingVisible" />
    <!-- 修改开台 -->
    <UpdatePeople @hiddenFounding="updateFounding" :updae_table_data="updateTableData" v-if="updataTableVisible" :update_table_visible="updataTableVisible" />
    <!-- 移菜 -->
    <MoveMenu @hiddenFounding="updateFounding" v-if="moveMenuVisible" :move_menu_visible="moveMenuVisible" :order_detail="order_detail" :move_menu_data="action_table_info"></MoveMenu>
    <!-- 换台 -->
    <TableMove @hiddenFounding="updateFounding" v-if="tableMoveVisible" :table_move_visible="tableMoveVisible" :table_move_data="action_table_info"></TableMove>
    <!-- 并台 -->
    <TableMerge @hiddenFounding="updateFounding" v-if="tableMergeVisible" :table_merge_visible="tableMergeVisible" :table_merge_data="action_table_info"></TableMerge>
    <!-- 一台多单 -->
    <TableSplit @hiddenFounding="updateFounding" v-if="tableSplitVisible" :table_split_visible="tableSplitVisible" :table_split_data="action_table_info" ></TableSplit>
    <!-- 撤台确认框 -->
    <a-modal v-model="chetaiModal" title="提示">
        <div>
            <div style="display:flex;align-items:center;">
                <a-icon style="color:#faad14;font-size:32px;margin-right:10px;" type="question-circle" />
                <span>确定对</span> 
                <span style="color:red;margin:0 5px;">{{action_table_info.table_name}}</span> 
                <span>进行撤台操作吗？</span>
            </div>
        </div>
        <template slot="footer">
            <div class="chetaiFooter">
                <a-button @click="chetaiModal = false">取消</a-button>
                <a-button type="primary" @click="withdrawHandle">确定</a-button>
            </div>
        </template>
    </a-modal>
    <!-- 已预订桌台开台提示框   -->
    <a-modal v-model="recentlyModal" title="提示">
        <div>
            <div style="display:flex;align-items:center;">
                <a-icon style="color:#faad14;font-size:32px;margin-right:10px;" type="question-circle" />
                <div>
                    <span>当前桌台</span>
                    <span style="color:red;margin:0 5px;">{{action_table_info.table_name}}</span> 
                    <span> {{action_table_info.is_recently_order_time && action_table_info.is_recently_order_time[0] == '-' ? '超过预定时间' : '距离预定时间还有' }}</span> 
                    <span style="color:red;margin:0 5px;">{{action_table_info.is_recently_order_time && action_table_info.is_recently_order_time[0] == '-' ? action_table_info.is_recently_order_time.replace(/-/g,'') : action_table_info.is_recently_order_time}}</span> 
                    <span>，是否继续开台？</span>
                </div>
                
            </div>
        </div>
        <template slot="footer">
            <div class="chetaiFooter">
                <a-button @click="recentlyModal = false">取消</a-button>
                <a-button type="primary" @click="recentlyHandle">确定</a-button>
            </div>
        </template>
    </a-modal>
</div>
</template>

<script>
import { tableList } from "@/request/api";
import Bus from "@/request/bus";
import { requestOrderDetail , backFounding } from "@/request/founding";
import { dateFormat } from "@/public/common";
import Founding from "./founding.vue";
import UpdatePeople from "./updatePeople.vue";
import MoveMenu from "./moveMenu.vue";
import TableMove from "./tableMove";
import TableMerge from "./tableMerge";
import TableSplit from "./tableSplit.vue";

export default {
    data() {
        return {
            tableListArr: [],
            dateFormat,
            //是否展示 开台弹框
            foundingVisible: false,
            //更新开台页
            updataTableVisible: false,
            //移菜
            moveMenuVisible: false,
            //换台
            tableMoveVisible: false,
            //并台
            tableMergeVisible: false,
            //一台多单(折台)
            tableSplitVisible: false,

            test:"is init",

            check_item: {},
            //订单详细
            order_detail: {},
            page: {
                //区域id
                storey_id: 0,
                //0: 全部 1:己开台 2:未开台
                is_founding: "",
                //餐桌编码
                table_coding: "",
                //速查码
                qiuck_coding: "",
                //桌台名称
                table_name: "",
                //区域名称
                storey_name: "",
                //当前页
                page: parseFloat(localStorage.getItem('page'))?parseFloat(localStorage.getItem('page')):1,
                //最后一页
                last_page: 0,
            },
            //当前被激活的桌台信息
            action_table_info: {},

            //需要更新的数据
            updateTableData: {},
            //右测点击事件
            rightEvent: "",
            //撤台确认框
            chetaiModal:false,
            //当前桌台订单详情
            orderData:{},
            //已预订桌台开台提示框
            recentlyModal:false,
            //完成预定页面携带数据
            routeData:{},
            //取消预定开台
            cancelOpen:true,
            url:'',
            dbClick:false,//是否双击
            timer:null,

        };
    },
    watch:{
        monitor:{
            handler(){
                this.tableListRequest();
                // alert(this.$store.state.speech_path)
                if(this.$store.state.speech_path){
                    bound.play(this.$store.state.speech_path)
                }
            },
            deep:true,
        }
    },
    computed:{
        monitor () {
		    return this.$store.state.refresh
	    }
    },
    mounted() {
        // 记录是否是完成预定跳转
        this.routeData = this.$route.query || {}
        //记录当前所选分类
        let tableCate = localStorage.getItem('tableCate')
        let cateType = localStorage.getItem('tableCateType')
        if(cateType){
            this.tableSelect(tableCate,cateType)
        }else{
            this.tableListRequest();
        }

        // 记录当前选中桌台
        let action_table_info = localStorage.getItem('action_table_info')
        if (action_table_info){
            this.action_table_info = JSON.parse(action_table_info) || {}
            setTimeout(() => {
                this.orderDetail(this.action_table_info);
            },500)
        }

        Bus.$off('categoryStatus')
        Bus.$on("categoryStatus", (categoryStatus,noChange,type) => {
            // localStorage.removeItem('page');
            if(!noChange){
                 localStorage.setItem( 'page' , 1);
                this.page.page=1;
                this.tableSelect(categoryStatus,type);
            }
        });
        //右测按钮事件
        Bus.$off('rightEvent')
        Bus.$on("rightEvent", (defaultVal) => {
            if (JSON.stringify(this.action_table_info) == "{}") {
                this.openNotification("操作提示", "请先选择桌台");
            }else{
                //未开台不允许操作动作
                if (this.action_table_info.is_founding == 0) {
                    let isNotEvent = [
                        "order",
                        "updateTable",
                        "kedanPrint",
                        "yujiedan",
                        "moveMenu",
                        "tableMove",
                        "tableMerge",
                        "tableSplit",
                        "cancelTable",
                        "settlement"
                    ];
                    
                    if (isNotEvent.indexOf(defaultVal)) {
                        this.openNotification("操作提示", "该桌台尚未开台");
                    }else{
                        //defaultVal == "order"
                        this.showFounding(this.action_table_info);
                    }
                    // this.openNotification("操作提示", "该桌台尚未开台");
                }else{
                    
                    if (defaultVal == "order") { //右测点菜按钮
                        this.showFounding(this.action_table_info);
                        
                    } else if (defaultVal == "updateTable") {  //修改桌台信息
                        this.updateTableData = this.action_table_info;
                        this.updataTableVisible = true;
                        
                    } else if (defaultVal == "yujiedan"){  //预结单
                        
                        
                    }else if (defaultVal == "kedanPrint") { //打印客单
                        let json ={key:"kedanAction", value:{founding_log_id:this.action_table_info.founding_log_id}} 
                        this.socketApi.websocketsend(json);
                    } else if (defaultVal == "moveMenu") { // 移菜
                        this.moveMenuVisible = true;
                    } else if (defaultVal == "settlement") { //结算
                        if(this.action_table_info.price == 0){
                            this.$message.error('该桌台还未点菜，不需要结算')
                        }else{
                            this.$router.push({
                                path: "/openTable",
                                query: {
                                    table_name: this.action_table_info.table_name,
                                    foundingLoId: this.action_table_info.founding_log_id,
                                    handle:'settlement'
                                },
                            });
                        }
                        
                                
                    }else if (defaultVal == "tableMove") { //换台
                        this.tableMoveVisible = true;
                    } else if (defaultVal == "tableMerge") {  //合台
                        this.tableMergeVisible = true;
                      
                    } else if (defaultVal == "tableSplit") {  //一台多单(拆台)
                        // console.log("table.vue tableSplit:", this.action_table_info);
                        this.test = "wo cao";
                        this.tableSplitVisible = true;      
                          
                    } else if (defaultVal == "duizhangdanPrint") {   //对账单 
                        let json ={key:"duizhangdanAction", value:{founding_log_id:this.action_table_info.founding_log_id}} 
                        // console.log("table.vue duizhuangdanPrint:", json);
                        //this.websocketsend(JSON.stringify(json));                
                    }else if(defaultVal == "cancelTable"){ //撤台
                       
                        if(this.action_table_info.price > 0){
                            this.$message.error('已点菜桌台不能撤台')
                        }else{
                            this.chetaiModal = true
                        }
                    }
                }
            }
        });

        ///搜索桌台
        Bus.$on("searchTable",table_name => {
            console.log('搜索桌台');
            
            this.page.table_name = table_name;
            localStorage.removeItem('page');
            this.page.page=1
            this.tableListRequest()
        })
        
    },
    updated(){
      
    },
    components: {
        Founding,
        UpdatePeople,
        MoveMenu,
        TableMove,
        TableMerge,
        TableSplit,
    },

    methods: {
        //开台
        showFoundings(data,yuding) {
            //已经开台跳转
            // this.action_table_info = data;
            clearTimeout(this.timer); 
            this.dbClick=!this.dbClick;
            this.timer=setTimeout(()=>{    // 使用异步函数的操作
                if(this.dbClick==true){      // 如果在300毫秒内用户没有点击 那么就是 true
                    console.log('用户点击')
                }else{                       //  否则用户再一次点击了 将其转换成初始的false状态 就是双击了
                    if (data.is_founding == 1) {
                        this.$router.push({
                            path: "/openTable",
                            query: {
                                table_name: data.table_name,
                                foundingLoId: data.founding_log_id,
                            },
                        });
                    }else if(data.is_recently_order == 1 && !yuding){
                        this.recentlyModal = true
                    }else{
                        this.check_item = data;
                        this.foundingVisible = true;
                    }
                        console.log('用户双击')
                }
                this.dbClick=false;    // 让其重新归位   保持原来的样式 如果不归位 变成 true的话  后面会出问题
            },300)
        },
         showFounding(data,yuding) {
            //已经开台跳转
            // this.action_table_info = data;
            if (data.is_founding == 1) {
                this.$router.push({
                    path: "/openTable",
                    query: {
                        table_name: data.table_name,
                        foundingLoId: data.founding_log_id,
                    },
                });
            }else if(data.is_recently_order == 1 && !yuding){
                this.recentlyModal = true
            }else{
                this.check_item = data;
                this.foundingVisible = true;
            }
        },
        //接受子组件回调的方法
        updateFounding(data) {
            
            this.foundingVisible = data;
            this.updataTableVisible = data;
            this.moveMenuVisible = data;
            this.tableMoveVisible = data;
            this.tableMergeVisible = data;
            this.tableSplitVisible = data;
            // this.$emit('prantFun')
            // console.log("updateFounding", this.action_table_info);
            

            // console.log("updateFounding", this.action_table_info);
                //初始化桌台信息,右测订单详情
            //     console.log('888')
            // this.page.page=1;
            this.tableListRequest();
            this.orderDetail(this.action_table_info);
        },
        //更新桌台-子组件回调
        updateTable(data) {
            this.updataTableVisible = true;
        },
        //订单详情
        orderDetail(data) {
            this.action_table_info = data;
            Bus.$emit("action_table_info", this.action_table_info);
            localStorage.setItem('action_table_info',JSON.stringify(data))
            //更新桌台状态
            let right_table_status = { founding: "未开台", people: 0 };
            if (data.is_founding == 1) {
                right_table_status = { founding: "已开台", people: data.people };
            }
            Bus.$emit("right_table_status", right_table_status);
            //更新订单
            //更新订单详情
            this.order_detail = [];
            let obj = { foundingLoId: data.founding_log_id };
            if(data.founding_log_id > 0){
                requestOrderDetail(obj).then((res) => {
                    if(res.data.status=200){
                         let data = res.data.data;
                        //console.log("orderDetail", data);
                        if (data.length == 0) {
                            this.order_detail = [];
                        } else {
                            this.order_detail = data.orderGoods;
                            this.orderData = data
                        }
                        Bus.$emit("order_detail", this.order_detail , this.orderData);
                    }
                   
                   
                    //console.log(data);
                }).catch((error) => {
                    return error;
                });
            }else{
                this.order_detail = [];
                Bus.$emit("order_detail", this.order_detail , {total_price:0});
            }
            
        },

        //全部餐桌列表请求
        tableListRequest() {
            const _this = this;
            console.log(JSON.stringify(_this.page));
            tableList(_this.page)
                .then((res) => {
                    const data = res.data.data.list.data ? res.data.data.list.data : [];
                    this.tableListArr = data;
                    this.page.last_page = res.data.data.list.last_page;
                    let index = this.tableListArr.findIndex(item => this.action_table_info.id == item.id)
                    if(index > -1){
                        this.action_table_info = this.tableListArr[index]
                        localStorage.setItem('action_table_info',JSON.stringify(this.action_table_info))
                    }else{
                        this.action_table_info = {}
                        localStorage.removeItem('action_table_info')
                        this.order_detail = []
                        Bus.$emit("order_detail", this.order_detail , {total_price:0});
                        let right_table_status = { founding: "未开台", people: 0 };
                        Bus.$emit("right_table_status", right_table_status);
                    }
                    if(this.routeData.table_id && this.cancelOpen){
                        this.cancelOpen = false
                        let ii = this.tableListArr.findIndex(item => this.routeData.table_id == item.id)
                        if(ii > -1){
                            this.action_table_info = this.tableListArr[ii]
                            localStorage.setItem('action_table_info',JSON.stringify(this.action_table_info))
                            this.showFounding(this.action_table_info,true)
                        }else{
                            this.$message.error('当前区域未找到预定桌台，请切换区域开台')
                        }
                    }
                    
                })
                .catch((error) => {
                    return false;
                });
        },

        //下一页
        nextPage() {
            if (this.page.page >= this.page.last_page) {
                this.openNotification("请求提示", "已经是最后一页了");
                return;
            }
            this.page.page += 1;
            localStorage.setItem( 'page' , this.page.page);
            this.tableListRequest();
            console.log(this.page.page)
           
            
        },
        //上一页
        upPage() {
            if (this.page.page <= 1) {
                this.openNotification("请求提示", "已经在第一页了");
                return;
            }
            this.page.page -= 1;
            localStorage.setItem( 'page' , this.page.page);
            this.tableListRequest();      
        },
        //提示框
        openNotification(title, content) {
            this.$message.error(title + ": " + content);
        },
        //桌台筛选
        tableSelect(id,type) {
            if (type == 'storey_id') {
                //证明选择的是区域
                this.page.is_founding = 0
                this.page.storey_id = id;
            } else {
                //0:全部, 1:已开桌台, 2:未开桌台
                this.page.storey_id = 0;
                this.page.is_founding = id;
            }
            // this.page.page = 1;
            // console.log(this.page);
            this.tableListRequest();
        },

        //打印客单
        kedan() {
            let printJson = {
                key: "print",
                print_name: "XP-80C",
                type: "kedan",
                content_list: [{
                        val: "******青年餐厅******",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    { val: "******客单******", type: "text", size: 12, lineHeight: 20 },
                    {
                        val: "******桌台: (8号)吉祥******",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    { val: "人数: 8 ", type: "text", size: 12, lineHeight: 20 },
                    {
                        val: "开台时间: 2021-01-21 10:00:00 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    { val: "服务员: xxxx ", type: "text", size: 12, lineHeight: 20 },
                    {
                        val: "------------------------------------ ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "-------------------面食--------------- ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "品名               单价             数量 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "牛肉面            20                1份 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "大雪碧            8                  1份 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "-------------------热菜--------------- ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "品名               单价             数量 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "(退)牛肉面            20                1份 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "(赠)大雪碧            8                  1份 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "------------------------------------ ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "总金额:                                     120.35",
                        type: "text",
                        size: 18,
                        lineHeight: 20,
                    },
                    {
                        val: "------------------------------------ ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                    {
                        val: "下单时间 2010-01-20 12:00:00:45 ",
                        type: "text",
                        size: 12,
                        lineHeight: 20,
                    },
                ],
            };
            // console.log(printJson);
            let str = JSON.stringify(printJson);
            bound.printListBtn(str);
        },

        //撤台
        withdrawHandle(){
            backFounding({foundingLoId : this.action_table_info.founding_log_id}).then(res => {
                if(res.data.status === 200){
                    this.$message.success('撤台成功')
                    this.chetaiModal = false
                    this.tableListRequest()
                }
            })
        },
        //已预订桌台开台提示
        recentlyHandle(){
            this.recentlyModal = false
            this.showFounding(this.action_table_info,true);
        }
    },
};
</script>

<style lang="less" scoped>
.cent {
    display: flex;

    .cent_left {
        width: 80%;
        height: 100%;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 0.7%;
            height: 100%;
            padding-top: 1vh;

            .founding-clear {
                background-color: #ffc300;
            }

            .founding_is_order {
                background-color: #fac7bc;
                border: #fac7bc solid 1px;
            }

            .is_select {
                border: 2px solid #ff2211;
                .yuding{
                    top: -3px!important;
                    right: -3px!important;
                }
            }

            li {
                height: 14.55vh;
                width: 10.3vw;
                background: #e4e4e4;
                margin-right: 0.7%;
                margin-bottom: 0.7%;
                border-radius: 5px;
                cursor: pointer;
                border: 1px solid #e4e4e4;
            }

            li:hover {
                border: 2px solid #ff2211;
                .yuding{
                    top: -3px;
                    right: -3px;
                }
            }

            .li {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1vh 1vh 0.6vh;
                .tablename {
                    color: rgba(80, 80, 80, 1);
                    // margin-top: 0.15rem;
                    // margin-left: 0.11rem;
                    font-size: 2vh;
                    font-weight: bold;
                    line-height: 0.24rem;
                    // text-align: center;
                    // margin-bottom: 0.13rem;
                }

                .createtime {
                    text-align: center;
                    font-size: 1.8vh;
                    line-height: 2vh;
                    // margin: 3.6vh 0 0 0;
                }

                .status {
                    text-align: center;
                    font-size: 0.8vw;

                    font-weight: bold;
                    color: white;
                }

                .people {
                    display: flex;
                    justify-content: space-between;
                    font-size: 1.8vh;
                    // margin: 0 0.11rem;
                    // position: absolute;
                    // width: 9vw;
                    // bottom: 1vh;

                    // .span(1) {
                    //     left: 0.8vw;
                    //     display: flex;
                    // }

                    // .span(2) {
                    //     right: 0.8vw;
                    //     display: flex;
                    // }
                }

                .yuding{
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: -2px;
                    right: -2px;
                    // background: #FA3C02;
                    // transform: rotate(-45deg);
                    border:20px solid #FA3C02;
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                    border-top-right-radius: 6px;
                    z-index: 99;
                    .yu{
                        position: absolute;
                        top:-18px;
                        right: -15px;
                        color: #ffffff;
                    }
                }
            }
        }

        .page {
            width: 14.6vw;
            position: absolute;
            top: 74.3vh;
            left: 65.6vw;

            .ant-btn {
                width: 10.3vw;
                height: 6.96vh;
                margin-bottom: 0.3vh;
                background-color: #444;
                border-color: #e4e4e4;
                color: #d44027;
                border: 0;
                font-size: 2vh;
            }
        }

        .page :hover {
            background-color: #242424;
        }
    }
}
.chetaiFooter{
    ::v-deep .ant-btn{
        &:hover,&:focus{
            border-color: #ff6700;
            color: #ff6700;
        }
    }
    ::v-deep .ant-btn-primary{
        background-color: #ff6700;
        border-color: #ff6700;
        &:hover{
            color: #ffffff;
        }
    }
}

</style>
