<template>
<div class="window">
    <!-- 点餐弹框 -->
    <a-modal v-model="visible" title="开台" ok-text="确认" cancel-text="取消" @cancel="handleCancel" :destroyOnClose="true" width="34vw">
        <template slot="footer">
            <span class="cancel">
                <a-button key="back" @click="handleCancel"> 取消 </a-button>
            </span>
            <span class="primary">
                <a-button key="submit" type="primary" @click="handleOk">
                    确定
                </a-button>
            </span>
        </template>

        <a-form-model ref="ruleForm" :model="data" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="用餐人数" prop="num">
                <a-input class="input"   ref="input" v-model="data.num" :min="dataItem.min_people" :max="99" @change="focushandle('num')" @focus="focushandle('num')" @keyup.enter.native="handleOk"  @select="selectText()"  @click="focusText"></a-input>
            </a-form-model-item>

            <a-form-model-item label="店员" prop="elerk"  v-if="$store.state.shop_info.is_salesman == 2">
                <a-select placeholder="请选择店员" v-model="data.elerk" allowClear>
                    <a-select-option v-for="(item, index) in form.clerk" :key="index" :value="item.clerk_id">
                        {{ item.real_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="桌台" style="height:3.6vh; line-height:3.6vh">
                <span style="font-weight: bold; height:3.6vh; line-height:3.6vh">
                    <a-input :value=" form.table_name" :disabled="true" type="input" @keyup.enter.native="handleOk"/></span>
            </a-form-model-item>

            <a-form-model-item label="备注">
                <a-input v-model="data.remark" @change="focushandle('remark')" @focus="focushandle('remark')" type="input"  @keyup.enter.native="handleOk" />
            </a-form-model-item>

            <keyNum @hidden_num="updateNum" :parentNum="key_num" :blur="blur"></keyNum>
        </a-form-model>
    </a-modal>
    <!-- 点餐弹框 -->
</div>
</template>

<script>
import { Founding, requestUserClerkList } from "@/request/founding";
import keyNum from "@/components/keycode/num";

export default {
    name: "Founding",
    data() {
        return {
            visible: false,
            labelCol: {
                span: 7,
            },
            wrapperCol: {
                span: 16,
            },
            data: {
                num: '0',
                remark:''
            },
            form: {
                num: '0',
                table_name: "",
                table_id: 0,
                remark: "",
                clerk: [{
                        id: "10007",
                        name: "kevin",
                    },
                    {
                        id: 10008,
                        name: "kevin2",
                    },
                ],
            },
            rules: {
                num: [{
                        required: true,
                        message: "请输入就餐人数",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]+$/,
                        message: "只能是整数",
                        trigger: "blur",
                    },
                ],
                elerk: [{
                    required: true,
                    message: "请选择店员",
                    trigger: "change",
                }, ],

            },
            key_num:'',
            key_code:'',
            blur:true,
        };
    },
    components: {
        keyNum,
    },
    watch:{
        visible:{
            handler(){
                if(this.visible==true){
                     this.$nextTick(() => {
                        this.$refs.input.focus();
                        this.$refs.input.select();
                    });
                    // window.addEventListener("click", this.changeCount);
                }
            },
            deep:true,
        },
        'data.num':{
            handler(){
                this.blur=false;
               console.log(this.blur)
            },
            deep:true,
        }
    },
    updated(){
    },
    // beforeDestroy () {
    //     window.removeEventListener("click",this.changeCount); // 取消监听
    // },
    props: {
        //接收父组件要开台的信息
        dataItem: {
            type: Object,
            default: () => {},
        },
        ////接收父组件传过来的显示弹框状态
        foundingVisible: {
            type: Boolean,
            default: () => false,
        },
        reserveData:{
            type:Object,
            default:{}
        }
    },
    methods: {
        changeCount(){
            this.$nextTick(() => {
                this.$refs.input.select();
            });
        },
        selectText(){
            this.blur=true;
            console.log( this.blur)
        },
        focusText(){
            this.blur=false;
            console.log( this.blur)
        },
        //取消后回传值 给父组件
        handleCancel(e) {
            this.visible = false;
            this.$emit("hiddenFounding", false);
        },

        //确认提交
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const model = this.$refs.ruleForm.model;
                    console.log('model',model);
                    
                    const data = {
                        order_clerk_id: model.elerk, 
                        people: model.num, 
                        remark: model.remark, 
                        table_id: this.form.table_id 
                    };
                    if(this.reserveData.table_id == this.dataItem.id){
                        data.reserve_id = this.reserveData.reserve_id
                    }
                    Founding(data)
                        .then((res) => {
                            const datas = res.data ? res.data : [];
                            if (datas.status == 200) {
                                console.log(datas);
                                this.$router.push({ path: "/openTable", query: { table_name: this.dataItem.table_name, foundingLoId: datas.data.founding_log_id } });
                            } else {
                                // this.openNotification('开台失败', datas.message);
                            }
                            this.tableListArr = datas;
                            console.log(JSON.stringify(this.tableListArr));
                        })
                        .catch((error) => {
                            this.openNotification('开台请求出错', error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        //获得导购信息
        userCheckList() {
            requestUserClerkList()
                .then((res) => {
                    let data = res.data.data;
                    if (res.data.status == 200) {
                        this.form.clerk = data.list;
                    } else {
                        this.openNotification('开台信息', res.data.message);
                    }
                })
                .catch((error) => {
                    this.openNotification('开台信息', error);
                });
        },
        //重置各项
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },

        //修改人数
        updateNum(num) {
            if(this.key_code){
                this.$set(this.data , this.key_code , num)
                this.key_num = num
                if(this.key_code == 'num'){
                    this.$refs.ruleForm.validateField('num')
                }
            }
        },

        //提示框
        openNotification(title, content) {
            this.$message.error(title + ": " + content);
        },
        //关闭回调
        autoClose() {},

        //输入框获取焦点 //输入框值变化
        focushandle(val){
            this.key_num = this.data[val]  + ''
            this.key_code = val
        }
    },
    mounted() {
        console.log('dataItem========',this.dataItem);
        console.log('routeData========',this.routeData);
        
        this.visible = this.foundingVisible ? true : false;
        this.data.num = this.dataItem.min_people.toString();
        this.form.table_name = this.dataItem.table_name;
        this.form.table_id = this.dataItem.id;
        this.userCheckList();
    },
};
</script>

<style lang="less" scoped>
/*改变系统畎认的弹框样式*/
/deep/ .ant-modal-content {
    background-color: #edeef2;

    .ant-input-number {
        width: 100%;
        height: 5vh;
        line-height: 5vh;
    }
}

/deep/ .ant-modal-header {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-modal-footer {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-form-item {
    margin-bottom: 1.2vh;
}

/deep/ .ant-form-item-required {
    // font-weight: bold;
}

/deep/ .ant-form-item-label {
    // font-weight: bold;
}

/deep/ .ant-form-item-required::before {
    /*margin-right: 15px;*/
}

/deep/ .ant-btn {
    margin-right: 2vw;
    margin-left: 1vw;
    width: 8vw;
    height: 4.6vh;
    font-size: 1.8vh;
}

/deep/ .ant-btn-primary {
    background: #ec661b;
    border: solid 1px #ec661b;
    margin-left: 2vw;
}

/deep/ .cancel {
    :hover {
        border: solid 1px #ed9c28;
    }
}

/deep/ .primary {
    :hover {
        border: solid 1px #ed9c28;
        background-color: #ed9c28;
    }
}

/deep/ .ant-modal-footer {
    text-align: center;
}

/deep/ .ant-select-selection--single {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-input {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-select-selection__rendered {
    line-height: 3.6vh;
    font-size: 1.8vh;
}
/deep/.ant-input:focus{
    border-color:white !important;
}
</style>
