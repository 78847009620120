<template>
    <div class="win">
        <!-- 寄存商品 -->
        <a-modal :title='title' :visible="isShow" @cancel="handleCancel" width="68vw">
            <div class="top">
                <div class="left">
                    <a-input placeholder="请输入商品名称" @change="accessList" v-model="keywords"/>
                    <a-button class="smallOkBtn" @click="search">查询</a-button>
                </div>
            </div>
            <div class="table">
                <a-table
                    :columns="columns"
                    :data-source="list"
                    bordered
                    :locale="{emptyText: '暂无数据'}"
                    :rowKey="(record, index) => {return record.wine_access_id;}"
                    :pagination="{
                        current: current,
                        total: total,
                        pageSize: 7,
                    }"
                    @change="handleTableChange"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                </a-table>
            </div>
            <div class="qvwin">取货</div>
            <div class="table">
                 <a-table
                    :columns="chooseColums"
                    :data-source="chooseList"
                    bordered
                    :locale="{emptyText: '暂无数据'}"
                    :rowKey="(record, index) => {return record.wine_access_id;}"
                    :row-selection="{ selectedRowKeys: qvKeys, onChange: qvChange }"
                 >
                    <span slot="takeout_number" slot-scope="record,text">
                       <a-input-number  v-model="text.takeout_number" :min="1" :max="text.residue_number" @change="changeNum(text)"></a-input-number>
                    </span>
                 </a-table>
            </div>
            <div class="code">
                <div class="yan" style="font-size:1.8vh">验证码取货</div>
                <a-switch checked-children="是" un-checked-children="否" v-model="switchButton"  @change="switchChange"/>
                <a-input-search @search="onSearch" placeholder="请输入验证码" enter-button="获取验证码" :disabled="inputDisabled" v-model="sms_code"/>
            </div>
            <template slot="footer">
                <div class="bottomBtn" >
                    <a-button class="clearBtn btn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn btn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import {getAccessList,getMobileSms,takeoutWineAccess} from "@/request/business";
export default {
    name:'orderRemark',
    props:['isShow',"wine_user_id","name"], 
    components:{}, 
    data(){
        return{
            columns:[
                {title: "商品名称",dataIndex: "wine_name",width:"16.6%"},
                {title: "存放数量",dataIndex: "residue_number",width:"16.6%"},
                {title: "单位",dataIndex: "wine_unit",width:"12.6%"},
                {title: "存放位置",dataIndex: "position_name",width:"16.6%"},
                {title: "存放日期",dataIndex: "create_time",width:"16.6%"},
                {title: "备注",dataIndex: "remark",width:"20.6%"},
            ],
            chooseColums:[
                {title: "商品名称",dataIndex: "wine_name",width:"25%"},
                {title: "存放数量",dataIndex: "residue_number",width:"25%"},
                {title: "取回数量",key:"takeout_number",width:"25%",scopedSlots: { customRender: "takeout_number" },},
                {title: "单位",dataIndex: "wine_unit",width:"25%"},
            ],
            showAdd:false,
            showPosition:false,
            list:[],
            total:0,
            keywords:"",//筛选-手机号或姓名
            current: 1, //表格当前页
            selectedRowKeys: [], //选中数据数组
            chooseList:[],//选中的tables数据
            qvKeys:[],
            switchButton:false,//验证码开关
            inputDisabled:true,
            is_sms_check:1,//是否开启验证码校验：1不开启2开启
            sms_code:"",//验证码数据
            title:"取回"
        }
    },
    watch:{
        isShow(val){
            if(val){
                this.accessList()
                this.title=`${this.name}取回`
            }
        },
    },
    methods:{
        changeNum(text){
            const arr=JSON.parse(JSON.stringify(this.chooseList))
            for(let i=0;i<arr.length;i++){
                if(arr.wine_access_id==text.wine_access_id){
                    arr.takeout_number=text.takeout_number
                }
            }
            this.chooseList=arr
        },
        handleOk(){
            const chooseArr=this.chooseList
            let arr=[]
            for(let i=0;i<chooseArr.length;i++){
                if(this.qvKeys.indexOf(chooseArr[i].wine_access_id) !=-1){
                    arr.push(chooseArr[i])
                }
            }
            const parmas={
                "wine_user_id":this.wine_user_id ,
                "is_sms_check": this.is_sms_check,
                "sms_code": this.sms_code,
                "takeout_json":arr
            }
            takeoutWineAccess(parmas).then(res => {
                if(res.data.status === 200){
                    this.$message.success('取回成功!请交付顾客商品')
                    this.handleCancel()
                    this.$emit('updateList')
                }
            })  
        },
        handleCancel(){
            this.keywords=""
            this.selectedRowKeys= []//选中数据数组
            this.qvKeys=[]
            this.chooseList=[]
            this.switchButton=false
            this.is_sms_check=1
            this.sms_code=""
            this.inputDisabled=true
            this.$emit('closeHandle')   
        },
        switchChange(checked){
            if(checked){
                this.inputDisabled=false
                this.is_sms_check=2
            }else{
                this.inputDisabled=true
                this.is_sms_check=1
            }
        },
        onSelectChange(selectedRowKeys){
            this.selectedRowKeys = selectedRowKeys;
            let arr=[]
            console.log(JSON.stringify(this.selectedRowKeys))
            for(let i=0;i<this.list.length;i++){
                if(selectedRowKeys.indexOf(this.list[i].wine_access_id) !=-1){
                    this.list[i].takeout_number=Number(this.list[i].residue_number)
                    arr.push(this.list[i])
                }
            }
            console.log(JSON.stringify(arr))
            this.chooseList=JSON.parse(JSON.stringify(arr))
            const brr=[]
            for(let i=0;i<this.chooseList.length;i++){
                brr.push(this.chooseList[i].wine_access_id)
            }
            this.qvKeys=brr
        },
        qvChange(keys){
            this.qvKeys=keys
        },
        accessList(){
            getAccessList({
                wine_user_id:this.wine_user_id,
                wine_name: this.keywords
            }).then(res => {
                if(res.data.status === 200){
                    this.list=res.data.data.list;
                }
            })
        },
        handleTableChange(e) {  //点击分页
            this.current = e.current;
            this.userList();
        },
        search(){
            this.current=1;
            this.userList()
        },
        onSearch(){
            getMobileSms({
                wine_user_id:this.wine_user_id,
            }).then(res => {
                if(res.data.status === 200){
                    this.$message.success('验证码发送成功')
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal{
    top:50px;
}
/deep/.ant-btn-primary{
    background:#ff6600 ;
    border-color: #ff6600;
    font-size: 1.8vh;
}
/deep/.ant-btn-primary[disabled]{
    background:#f5f5f5 !important;
    border-color: #d9d9d9 !important;
}
/deep/.ant-switch-checked {
    background:#ff6600 ;
}
/deep/.ant-input-group .ant-input{
    height:5.2vh;
}
/deep/.ant-btn-primary{
    height: 5.2vh;
}
.qvwin{
    padding-top:2.3vh;
    font-size: 2vh !important;
    font-weight: bold !important;
    border-top:1px solid #e4e4e4 ;
}
/deep/.ant-modal-content{
    background: #edeef2;
    .ant-modal-header{
       background: #edeef2; 
    }
    .ant-modal-body{
        padding:1.8vh 24px;
        .top{
            display: flex;
            justify-content: space-between;
            .left{
                display: flex;
                .ant-input{
                width:23vw;
                height: 4.6vh; 
                border-radius: 2.3vh;
                }
                .smallOkBtn{
                    height:4.6vh;
                    margin-left: 1.3vw;
                    width:7vw;
                }
            }
        }
        .table{
            margin-top: 2vh;
            th{
                background: #edeef2; 
                font-weight: bold;
                font-size: 1.8vh;
                padding:10px 10px;
            }
            td{
                background: #fff;
                font-size: 1.8vh;
                padding:10px;
                padding:10px 10px;
            }
            .qv{
                color:#ff6600;
                cursor: pointer;
            }
        }
        .code{
            display: flex;
            background: #fff;
            height:12.5vh;
            justify-content:center;
            align-items: center;
            .ant-switch{
                margin-left: 1vw;
               margin-right:3vw;
            }
        }
    }
    .ant-modal-footer{
       text-align: center;
       .OkBtn{
           margin-left:4.3vw;
       }
    }
    .ant-input-group-wrapper{
        width:50%;
    }
}
</style>