<template>
  <div class="bottom">
    <div class="bottom_top">
      <p>
        <span>当天未结账单：</span>
        <span>{{bottomData.notFinishNum}}</span>
        <span>单/</span>
        <span>{{bottomData.notFinishPrice}}</span>
        <span>元/</span>
        <span>{{bottomData.notFinishPeople}}</span>
        <span>人</span>
      </p>
      <p>
        <span>本日已结账单</span>
        <span></span>
        <span>{{bottomData.finishNum}}</span>
        <span>单</span>
      </p>
      <p>
        <span>当天优惠总额</span>
        <span></span>
        <span>{{bottomData.discountPrice}}</span>
        <span>元</span>
      </p>
    </div>
    <div class="bottom_b">
      <div class="other">
        <div class="footLeft">
          <a-dropdown>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="1"> <a-icon type="user" />会员查询(F4)</a-menu-item>
              <a-menu-divider />
              <a-menu-item key="2"> <a-icon type="money-collect" />会员充值(F4) </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3"> <a-icon type="usergroup-add" />新增会员(F3) </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="4"> <a-icon type="notification" />沽清 </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="5"> <a-icon type="snippets" />预定(F9) </a-menu-item>
              <a-menu-item key="6"> <a-icon type="notification" />限量沽清(F10) </a-menu-item>
              <a-menu-item key="7"> <a-icon type="project" />商品寄存 </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px;font-size:  2vh;"> 其他菜单 <a-icon type="up" /> </a-button>
          </a-dropdown>
          <!-- <div class="time">{{ nowTime }}</div> -->
          <div class="lock"><div class="time" @click="lockClick">锁屏</div></div>
         </div>
          <div class="footright">
            <div class="parent"><div class="banci" @click="shiftHandle">交班</div></div>
            <div class="search">
              <a-input-search placeholder="搜索桌台" v-model="form.username" @change="onSearch" @search="onSearch" />
            </div>
            <div class="jianpan" @click="showCode = !showCode">
              <img :src="require('@/assets/键盘.svg')" alt="" />
            </div>
            <div class="code" v-if="showCode">
              <keyCode :keyboards="keyboards" :onSubmit="onSubmit" @updatekey="updatekey" :form="form"></keyCode>
            </div>
          </div>
          
      </div>
    
    </div>
    <addMis :isShow="showAddMis" @changeModal="changeAdd"/>
    <Recharge :isShow="showRecharge" @changeModal="changeRecharge" :functionJump="true"/>
    <Shift :showShift="showShift" v-if="showShift" @hidn="showShift = false"></Shift>
    <addReserve :isShow="showAddReserve"  @choose="changeReserve"/>
    <stockModal :isShow="showStock" @cancle="changeStock" v-if="showStock"/>
    <winModel :isShow="showWin" @closeHandle="showWin=false"/>
  </div>
</template>
<script>
import { tableBottom } from "@/request/founding";
import Bus from "@/request/bus";
import addMis from '@/views/mis/modules/Addmis'
import Recharge from "@/views/mis/modules/Recharge.vue";
import { mapState , mapActions } from "vuex";
import Shift from "./shift/shift"
import keyCode from "@/components/keycode/keycode";
import addReserve from '@/views/reserve/modules/addReserve.vue'
import stockModal from "./stockModal.vue";
import winModel from "./win/win.vue"
export default {
  data() {
    return {
      topList: [],
      categorActive: "全部桌台",
      labelCol: { span: 4 },
      liDate: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", "---"],
      ///开台桌号
      taiNum: null,

      Num: 1,
      Name: "",
      Bei: "",
       showShift:false,
      //表格数据
      columns: [
        {
          title: "顺序",
          dataIndex: "num",
        },
        {
          title: "操作",
          dataIndex: "click",
          scopedSlots: { customRender: "Icon" },
        },
        {
          title: "原打印机",
          dataIndex: "name",
        },
        {
          title: "实际打印机",
          dataIndex: "name1",
        },
        {
          title: "打印内容",
          dataIndex: "cent",
        },
        {
          title: "打印状态",
          dataIndex: "tai",
        },
        {
          title: "客户端机器",
          dataIndex: "qi",
        },
        {
          title: "添加时间",
          dataIndex: "time",
        },
      ],

      visibleDian: false,
      visible: false,
      visibleTab: false,
      nowDate: null, //存放年月日变量
      // nowTime: null, //存放时分秒变量
      // timer: "", //定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      wrapperCol: { span: 14 },
      bottomData:{},  //当日统计
      action_table_info:{},
      showAddMis:false,//新增会员
      showRecharge: false, //会员充值、会员查询
      showCode:false,
      showAddReserve:false,
      keyboards:'username',
      form:{
        username:''
      },
      showStock:false,
      showChooseStock:false,
      showWin:false,
    };
  },
  computed: {
    ...mapState(["topTabList",'activeName']),
  },
  components: {addMis,Recharge,Shift,keyCode,addReserve,stockModal,winModel},
   created(){
     this.keyUp()
    },
  methods: {
    changeAdd(){
      this.showAddMis = false;
      this.keyUp()
    },
    changeRecharge(){
      this.showRecharge = false;
      this.keyUp()
    },
    changeReserve(){
      this.showAddReserve=false;
      this.keyUp()
    },
    ...mapActions(['updateTabListAction','activeNameAction']),
    //补打
    hideModal() {
      // console.log(1111);
    },
    ///表格数据
    ///打印异常按钮
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    ///打印异常
    abnormal() {
      this.visible = true;
    },
    ////点菜进入详情
    handleMenuClick(e) {
      console.log('eeeeeeeeeeeeeee',e)
      // this.$router.push("/order");
      if(e.key == 1 || e.key == 2){
        this.showRecharge=true;
      }else if(e.key == 3){
        this.showAddMis = true
      }else if(e.key == 4){
        let i = this.topTabList.findIndex(item => item.name == 'stock')
        if(i == -1){
          let arr = JSON.parse(JSON.stringify(this.topTabList))
          arr.push({
            isDetele: true,
            name: "stock",
            path: "/stock/goodsList",
            title: "库存管理"
          })
          this.updateTabListAction(arr)
        }
        this.activeNameAction('stock')
        this.$router.push('/stock/goodsList?key=0')
      }else if(e.key == 5){
        // if(this.action_table_info.founding_log_id > 0){
        //   let json ={key:"kedanAction", value:{founding_log_id:this.action_table_info.founding_log_id}} 
        //   this.socketApi.websocketsend(json);
        // }
        this.showAddReserve=true;
      }else if(e.key == 6){
        this.showStock=true;
      }else if(e.key == 7){
        this.showWin=true
      }
    },
    lockClick(){
      this.$router.push("/Password");
    },
    // getTime() {
    //   const date = new Date();
    //   const year = date.getFullYear();
    //   const month = date.getMonth() + 1;
    //   const day = date.getDate();
    //   const hour = date.getHours();
    //   const minute = date.getMinutes();
    //   const second = date.getSeconds();
      
    //   this.month = check(month);
    //   this.day = check(day);
    //   this.hour = check(hour);
    //   this.minute = check(minute);
    //   this.second = check(second);
    //   function check(i) {
    //     const num = i < 10 ? "0" + i : i;
    //     return num;
    //   }
    //   this.nowDate = year + "年" + this.month + "月" + this.day + "日";
    //   this.nowTime = this.hour + ":" + this.minute + ":" + this.second ;
    //   // console.log(this.nowTime)
    // },
     againdata() {
      this.$confirm({
        title: "提示",
        content: "确定要重新加载页面么?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          window.location.reload();
        },
        onCancel() {},
      });
    },
    onSearch(){ //软件盘搜索
      Bus.$emit('searchTable',this.form.username)
    },
    onSubmit(val){
      this.onSearch()
    },
    updatekey(val){
      console.log(val);
      this.$set(this.form , 'username' ,  val)
      this.onSearch()
    },
    shiftHandle(){
      if(this.$route.query.jiaoban){
        this.$router.push({
          path:'/yingye',
        })
      }
      this.showShift = true
    },
    keyUp(){
      const _this=this;
      document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
          if (e1 && e1.keyCode == 117) {//点击F5结算
               _this.$emit("parentFnOne");
            }else if(e1 && e1.keyCode == 116){
                _this.$emit("parentFnTwo");
            }else if(e1 && e1.keyCode == 115){
              _this.handleMenuClick({key:1})
            }else if(e1 && e1.keyCode == 114){
               _this.handleMenuClick({key:3})
            }else if(e1 && e1.keyCode == 120){
               _this.handleMenuClick({key:5})
            }else if(e1 && e1.keyCode ==121){
              _this.showStock=true;
            } 
        }
    },
    changeStock(){
      this.showStock=false;
  }
  },
  mounted() {
    // this.timer = setInterval(this.getTime, 1000);
    //this.caiData()
    tableBottom().then(res => {
      if(res.data.status === 200){
        this.bottomData = res.data.data
      }
    })
    Bus.$off('action_table_info')
    Bus.$on("action_table_info", action_table_info => {
      console.log('bottom------组件----action_table_info',action_table_info);
      
        this.action_table_info = action_table_info
    });
    if(this.$route.query.jiaoban){
      setTimeout(() => {
        this.showShift = true
      },500)
    }
  },
};
</script>
<style lang="less" scoped>
.bottom {
  width: 100%;
  background: #333333;

  bottom: 0;
  .bottom_top {
    background: white;
    display: flex;
    height: 3vh;
    line-height: 3vh;
    font-size: 1.4vh;
    p:nth-of-type(1) {
      margin-left: 3%;
      margin-right: 30px;
      span:nth-of-type(2) {
        color: #ff0000;
        margin-right: 5px;
      }
      span:nth-of-type(4) {
        color: #ff0000;
        margin-right: 5px;
        margin-left: 5px;
      }
      span:nth-of-type(6) {
        color: #ff0000;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    p:nth-of-type(2) {
      margin-right: 20px;
      span:nth-of-type(3) {
        margin-left: 5px;
        color: #ff0000;
      }
    }
    p:nth-of-type(3) {
      margin-right: 20px;
      span:nth-of-type(3) {
        margin-left: 5px;
        color: #ff0000;
      }
    }
  }
  .bottom_b {
    color: white;
    display: flex;
    .other {
      margin-left: 1vw;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right:1.5vw;
    }
    .other button {
      width: 12vw;
      height: 5.6vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 1.1vw;

      color: black;
      font-weight: bold;
      background: #e4e4e4;
    }
    .time {
      width: 10vw;
      height: 5.6vh;
      line-height: 5.5vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 2vh;
      color: #333333;
      background-color:#e4e4e4;
    }
    .lock {
     :hover{
      background-color: #595758;
      color: #e4e4e4;
      cursor: pointer;
     }
    }

   .print {
     :hover{
      background-color: #ff0000;
      color: #e4e4e4;
      cursor: pointer;
     }
    }
    .right{
    position: absolute;
    right: 1vw;
    }
  }
}
/deep/ .ant-dropdown-menu-item{
    height: 6vh;
    line-height: 6vh;
    font-size: 1.8vh ;
    
}
/deep/ .ant-dropdown-menu-item > .anticon:first-child{
    font-size: 1.8vh;
}
.parent {
      .banci {
        display: block;
        width: 10vw;
        min-width: 102px;
        line-height: 5.6vh;
        height: 5.6vh;
        font-size:  2vh;
        margin: 0.8vh 0 0.8vh 1.5vw;
        padding: 0 1vw 0 1vw;
        border-radius: 0.2vw;
        list-style: none;
        
        text-align: center;
        cursor: pointer;
        background: #e4e4e4;
        color: #333333;
      }
      :hover {
        background: #595758;
        color: #e4e4e4;
        text-align: center;
      }
} 
.footright{
  display: flex;
  align-items: center;
}
 .search {
      display: flex;
      width: 10vw;
      line-height: 5.6vh;
      height: 5.6vh;
      // margin: 1.5vh 0vw 0vw 0.6vw;
      margin-left: 1vh;
      /deep/ .ant-input{
      font-size: 2vh;
      height: 5.6vh;
      border: 0;
      border-radius: 0.4vh 0 0 0.4vh;
      border-right: 1px solid #dddddd;
      width: 10vw;
      }
    }
    .jianpan {
      width: 4vw;
      display: flex;
      height: 5.6vh;
      // margin: 1.5vh 0 0 0;
      margin-left: -0.5vw;
      background-color: #fff;
      border-radius: 0.4vh;
      cursor: pointer;
      img {
        width: 3.6vw;
        padding-left: 1vw;
      }
    } 
    .footLeft{
      display: flex;
    } 
  .code{
    position: fixed;
    right: 4vw;
    bottom:8vh;
    background: #f2f2f2;
    padding:1vw 1vw;
    padding-right: 0.5vw;
    border-radius: 4px;
  }
</style>
