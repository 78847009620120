<template>
  <div>
    <a-modal
      :visible="isShow"
      title="快速收银"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
          :label-col="{ span: 4}" 
          :wrapper-col="{ span: 10}"
        >
          <a-form-model-item ref="price" label="收到" prop="price">
            <a-input
              class="input"
              v-model="form.price"
              placeholder="请输入收款金额"
              @keyup.enter.native="handleOk"
            ></a-input>
          </a-form-model-item>
          <div class="ji">
            <keyNum :parentNum="this.form.price" @hidden_num="updateNum"/> 
          </div>         
      </a-form-model> 
      <smModel :isShow="showSm" @closeHandle="showSm=false" :current_price="form.price" @clearPrice="form.price=''"/>
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num.vue";
import smModel from './sm_model.vue'
export default {
  props:['isShow','sheng','type'],
  components:{keyNum,smModel},
  data() {
    return {
       form: {
          price:"",
        },
        rules: {
          price: [
            {required: true,  trigger: "change" ,message: "请输大于0的数字",},
          ] 
        },
        showSm:false,
   }
  },
  methods: {
    updateNum(num){
      this.form.price = num;
    }, 
    handleCancel(){
      this.form.price="";
      this.$emit('closeHandle',false)//关闭按钮
    }, 
    handleOk(){
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
            this.showSm=true   
            this.$emit('closeHandle',false)//关闭按钮  
        } else {
          return false;
        }
      }); 
    },
  },
};
</script>
<style lang="less" scoped>
.ji{
  padding:0 2vw;
}
/deep/.ant-modal-content{
  width: 35vw;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-form-item-label {
  margin-top: 2vh;
  font-size: 2.2vh;
  height:6vh;
  line-height: 6vh;
}
/deep/.ant-form-item{
  margin-bottom:3vh;
}
/deep/.ant-form-item-control {
  margin-top: 2vh;
}
.ant-input {
  width: 18vw;
  border-color: #999999;
  background: #edeef2;
  height:6vh;
  font-size: 2.0vh;
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-btn {
  width: 13vh;
  height: 6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  width:13vh;
  height:6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding: 0 1vw;
}
/deep/.ant-modal-title{
  font-size: 2.0vh;
  font-weight: bold;
}
/deep/.ant-form label{
  font-size: 2.0vh;
  color:rgba(0, 0, 0, 0.65);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.anticon svg{
  font-size: 2.8vh;
  color:#999999;
  margin-top:1.5vh;
}
</style>