<template>
    <div class="win">
        <!-- 商品存放 -->
        <a-modal title="存放" :visible="isShow" @cancel="handleCancel" width="68vw">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item label="手机号" prop="mobile"  :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-input  v-model="form.mobile"  placeholder="选择/输入手机号" @change="getPhone"/>
                    <div class="searchPhone" v-if="showPhoneList">
                        <div v-for="(item,index) in searchPhone" :key="index" @click="form.mobile=item.mobile;showPhoneList=false" class="items">
                            <div class="mobile">{{item.mobile}}</div>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="name" label="存放人" prop="name">
                    <a-input  v-model="form.name"  placeholder="请填写存放人名称"/>
                </a-form-model-item>
                <a-form-model-item label="存放商品" prop="wine_json" class="cun">
                    <div class="item" v-for="(item,index) in goodsArr" :key="index">
                        <a-input  v-model="goodsArr[index].wine_name"  placeholder="请填写商品名称"/>
                        <a-input-number :min="1" v-model="goodsArr[index].wine_number"/>
                        <a-select  placeholder="请选择" v-model="goodsArr[index].wine_unit">
                            <a-select-option v-for="(item,index) in unitsArr" :key="index" :value="item.goods_unit_name" placeholder="商品单位">
                                {{item.goods_unit_name}}
                            </a-select-option>
                        </a-select>
                        <!-- <div class="jian">删除</div> -->
                    </div>
                    <div class="jia" @click="jiaButton">+</div>
                </a-form-model-item>
                <a-form-model-item label="存放位置" prop="position_name">
                    <a-select v-model="form.position_id" placeholder="请选择" @change="handleChange">
                        <a-select-option v-for="(item,index) in location" :key="index" :value="item.position_id">
                            {{item.position_name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="备注" prop="region">
                    <a-input  v-model="form.remark"  placeholder="请填写备注"/>
                </a-form-model-item>
            </a-form-model>
            <template slot="footer">
                <div class="bottomBtn" >
                    <a-button class="clearBtn btn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn btn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { units } from "@/request/fast_food_settle";
import { getAccessPositionList,createWineAccess,searchWineUser} from "@/request/business";
export default {
    name:'orderRemark',
    props:['isShow'],  
    data(){
        return{
            form:{
                mobile:"",//手机号
                remark:undefined,//备注
                wine_json:[],//存储的物品
                name:"",//存放人姓名
                position_id:undefined,//存放位置
                position_name:"",//存放位置名称
            },
            count:1,
            labelCol: { span: 4 },
            wrapperCol: { span: 6 },
            rules: {
                mobile:[
                    {required: true, message: '请选择/输入手机号', trigger: 'blur' },
                ],
                wine_json:[
                     {required: true, message: '请填写商品名称', trigger: 'blur' },
                ]
            },
            unitsArr:[],//商品单位
            location:[],//存放位置
            goodsArr:[
                {wine_name:"",wine_number:1,wine_unit:undefined,},  
            ],//商品数组
            searchPhone:[],//搜索手机数组
            showPhoneList:false   
        }
    },
    watch:{
        isShow(val){
            if(val){
                units().then(res => {
                    if(res.data.status === 200){
                        const data=res.data.data.list && res.data.data.list;
                        this.unitsArr=data;
                    }
                }) 
                getAccessPositionList().then(res => {
                    if(res.data.status === 200){
                        const data=res.data.data.list && res.data.data.list;
                        this.location=data
                        data.filter(item=>{
                            if(item.is_default==2){
                                this.form.position_id=item.position_id
                                this.form.position_name=item.position_name
                            }
                        })
                    }
                })

            }
        }
    },  
    methods:{
        handleOk(){
            const arr= this.goodsArr.filter(item=>{
                if(item.wine_name && item.wine_unit){
                    return item
                }     
            })
            this.form.wine_json=arr;
            // console.log(JSON.stringify( this.form))
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    createWineAccess(this.form).then(res => {
                        if(res.data.status === 200){
                            this.$message.success('存放成功')
                            this.$emit("updateList")
                            this.handleCancel()
                        }
                    }) 
                } else {
                    return false;
                }
            });
        },
        handleCancel(){
            this.form={
                mobile:"",//手机号
                remark:undefined,//备注
                wine_json:[],//存储的物品
                name:"",//存放人姓名
                position_id:undefined,//存放位置
                position_name:"",//存放位置名称
            }
            this.goodsArr=[{wine_name:"",wine_number:1,wine_unit:undefined,} ],//商品数组
            this.searchPhone=[]
            this.$emit('closeHandle')
        },
        jiaButton(){
          this.goodsArr.push({wine_name:"",wine_number:1,wine_unit:undefined,}) 
        },
        handleChange(value){
            for(let i=0;i<this.location.length;i++){
                if(this.location[i].position_id==value){
                    this.form.position_name=this.location[i].position_name;
                }
            }
        },
        getPhone(){
            searchWineUser({mobile:this.form.mobile}).then(res => {
                if(res.data.status === 200){
                    const data =res.data.data.data && res.data.data.data;
                    this.searchPhone=data;
                    if(data.length>0){
                        this.showPhoneList=true;
                    }
                }else{
                    this.searchPhone=[]; 
                }
             })
        }

    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal-content{
    background: #edeef2;
    .ant-modal-header{
       background: #edeef2; 
    }
    .ant-modal-body{
        padding:1.8vh 24px;
        .ant-form-item{
            display: flex;
            position: relative;
            .searchPhone{
                width:28.7vw ;
                background: #fff;
                border-radius: 4px;
                box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.09285714285714292);
                line-height: 32px;
                position: absolute;
                left:0;
                z-index: 10;
                cursor: pointer;
                .items{
                    .mobile{
                        padding-left: 14px;
                    }
                    &:hover{
                        background: #e6f7ff;
                    }
                }
            }
            .ant-form-item-label{
                width:7vw;
                font-size: 1.8vh; 
                height:4.6vh !important;
                line-height: 4.6vh;
            }
            .ant-input{
                width:28.7vw;
                height:4.6vh;
            }
            .ant-select{
                width:28.7vw;
                height:4.6vh;
                .ant-select-selection--single{
                    height:4.6vh;
                    .ant-select-selection__rendered{
                        line-height: 4.6vh;
                    }
                }
            }
            .item{
                display: flex;
                margin-top: 1vh;
                .ant-input{
                    width:28.7vw;
                    height:4.6vh;
                }
                .ant-input-number{
                    width: 9vw !important;
                    height:4.6vh;
                    margin-left: 1.3vw;
                }
                .ant-select{
                     width: 9vw !important;
                     margin-left: 1.3vw;
                    .ant-select-selection--single{
                        height:4.6vh;
                    }  
                }
            }
            .jia{
                width:28.7vw;
                height:4.6vh;
                text-align: center;
                border: rgba(205, 205, 205, 1) dashed 1px;
                border-radius: 4px;
                line-height: 4.6vh;
                color:#9e9e9e;
                margin-top: 1vh;
                cursor: pointer;
                font-size: 1.8vh;
            }
        }
        // .jian{
        //     width:4.6vh;
        //     height:4.6vh;
        //     border: 1px solid gray;
        //     text-align: center;
            
        // }
    }
    .ant-modal-footer{
        text-align: center;
        .OkBtn{
            margin-left: 4.3vw;
        }
    }
    .ant-select-selection__rendered{
        font-size: 1.8vh;
    }
    .ant-input-number-input{
        line-height: 4.6vh !important;
        height: 4.6vh;
    }
    .cun{
        .ant-form-item-label{
            height: 4.6vh !important;
            line-height: 4.6vh !important;
            margin-top:1vh;
        }
    }
}
</style>