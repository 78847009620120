<template>
  <a-layout>
    <a-layout-header style="padding: 0; height: 5vh; line-height: 5vh">
      <Header :headername="headerName" />
    </a-layout-header>
    <a-layout-content>
      <!-- <keep-alive> -->
      <div class="ying">
        <!-- 头部 -->
        <Category />
        <!-- 头部 -->
        <!-- 内容 -->
        <div class="cent">
          <Table />
          <Right ref="right"/>
        </div>
        <!-- 内容 -->
      </div>
    </a-layout-content>
    <a-layout-footer style="padding:0;">
      <!-- 底部 -->
      <Bottom ref="bottom" @parentFnOne="dianCai" @parentFnTwo="jieZhang"></Bottom>
    </a-layout-footer>
  </a-layout>

  <!-- </keep-alive> -->
</template>
<script>
////表格数据
import { mapState } from "vuex";
import Header from "@/components/touTop/touTop.vue";
import Table from "./modules/table.vue";
import Category from "./modules/category.vue";
import Bottom from "./modules/bottom.vue";
import Right from "./modules/right.vue";
export default {
  components: {
    Header,
    Table,
    Category,
    Bottom,
    Right,
  },

  data() {
    return {
      headerName: "营业",

      indexShow: 1,

      Numpeop: false,

      
      menuData: [],

      isKaiTai: 0, //是否开台
      isQingTai: "", //是否清台
      speech_path:'https://static.yt91.cn/%E5%98%B4%E5%B7%B4%E5%98%9F%E5%98%9F_65123193.mp3',
    };
  },
  watch:{
     monitor:{
      handler(){
        const _this=this;
        // this.speech_path=this.$store.state.speech_path ? this.$store.state.speech_path:'' ;
        // alert( this.speech_path)
         if(this.speech_path){
            //      this.$nextTick(() => {
            //       _this.$refs.audio.play()
            // })
              }  
      },
      deep:true,
    }
  },
  computed: {
    ...mapState(["shop_id"]),
     monitor () {
		      return this.$store.state.refresh
	    }

  },


  methods: {
    btnBack() {
      this.$router.push({ path: "/about", query: { title: "营业" } });
    },
    dianCai(){
      // alert('1111')
      this.$refs.right.showFounding();
    },
    jieZhang(){
      // alert('3333')
      this.$refs.right.settlement();
    },
    diaoFun(){
      // // alert('3333');
      // this.refs.bottom.keyUp()
      //   const _this=this;
      // document.onkeydown = function(e) {
      //       let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
      //     if (e1 && e1.keyCode == 117) {//点击F5结算
      //         this.$refs.right.showFounding();
      //       }else if(e1 && e1.keyCode == 116){
      //          this.$refs.right.settlement();
      //       }else if(e1 && e1.keyCode == 115){
      //         _this.$refs.bottom.handleMenuClick({key:1})
      //       }else if(e1 && e1.keyCode == 114){
      //          _this.handleMenuClick({key:3})
      //       }else if(e1 && e1.keyCode == 120){
      //          _this.$refs.bottom.handleMenuClick({key:5})
      //       } 
      //   }
    }


  },
 
  
 

  mounted() {
  },
};
</script>

<style lang="less" scoped>
html,
body,
#app {
  height: 100vh;
}

.ying {
  width: 100%;
  height: 84.8vh;
  background: #b4b4b6;
  .ulJian {
    width: 300px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: 80px;
    li {
      width: 100px;
      height: 40px;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
      border-bottom: 1px solid #b5b5b6;
      border-right: 1px solid #b5b5b6;
      cursor: pointer;
    }
    li:nth-of-type(3) {
      border-right: none;
    }
    li:nth-of-type(6) {
      border-right: none;
    }
    li:nth-of-type(9) {
      border-right: none;
    }
    li:nth-of-type(12) {
      border-bottom: none;
      border-right: none;
    }
    li:nth-of-type(10) {
      border-bottom: none;
    }
    li:nth-of-type(11) {
      border-bottom: none;
    }
    li:hover {
      background: #eb6617;
    }
  }

  .cent {
    display: flex;

  }
 
}
</style>
