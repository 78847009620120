<template>
<div class="window">
    <!-- 一台多单(开台) -->
    <a-modal v-model="visible" title="一台多单(开台)"  @cancel="handleCancel" :destroyOnClose="true" width="34vw" >
        <template slot="footer">
            <span class="cancel">
                <a-button key="back" @click="handleCancel"> 取消 </a-button>
            </span>
            <span class="primary">
                <a-button key="submit" type="primary" @click="handleOk">
                    确定
                </a-button>
            </span>
        </template>

        <a-form-model 
        ref="ruleForm"  
        :rules="rules" 
        :model="form" 
        :label-col="labelCol" 
        :wrapper-col="wrapperCol"
        >
            <a-form-model-item label="用餐人数"  prop="num">
                <a-input class="input" ref="input" :min="1" :max="9"  v-model="form.num" @change="focushandle('num')" @focus="focushandle('num')" @keyup.enter.native="handleOk" @select="selectText()"  @click="focusText"></a-input>
            </a-form-model-item>

            <a-form-model-item label="店员"  prop="elerk"  v-if="$store.state.shop_info.is_salesman == 2">
                <a-select  v-model="form.elerk" placeholder="请选择店员">
                    <a-select-option v-for="(item, index) in data.elerk" :key="index" :value="item.clerk_id">
                        {{ item.real_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="桌台" style="height:3.6vh; line-height:3.6vh">
                <span style="font-weight: bold; height:3.6vh; line-height:3.6vh">
                    <a-input  :value=" data.table_name" :disabled="true" type="input" /></span>
            </a-form-model-item>

            <a-form-model-item label="备注">
                <a-input  type="input" v-model="form.remark" @change="focushandle('remark')" @focus="focushandle('remark')" @keyup.enter.native="handleOk"/>
            </a-form-model-item>

            <keyNum @hidden_num="updateNum" :parentNum="key_num" :blur="blur"></keyNum>
        </a-form-model>
    </a-modal>
    <!-- 一台多单(开台) -->
</div>
</template>

<script>
import { requestTableSplit, requestUserClerkList } from "@/request/founding";
import keyNum from "@/components/keycode/num";

export default {
   
    data() {
        return {
            visible: false,
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 16,
            },
            data: {
                num: "0",
                table_name:"",
                remark:"",
                elerk:""
            },
            form: {
                num: "1",
                table_id: 0,
                remark: "",
                elerk:undefined,                
            },
            rules: {
                num: [{
                        required: true,
                        message: "请输入就餐人数",
                        trigger: "change",
                    },
                    {
                        pattern: /^[0-9]+$/,
                        message: "只能是整数",
                        trigger: "change",
                    },
                ],
                elerk: [{
                    required: true,
                    message: "请选择店员",
                    trigger: "change",
                }, ],

            },
            key_num:'',
            key_code:'',
            blur:true,
        };
    },
    components: {
        keyNum,
    },
    props: {
        //接收父组件传过来的显示弹框状态
        table_split_visible: {},
        //接收父组件要开台的信息
        table_split_data:{},        
    },
    // watch: {
    //     //每次传数据进来后更新子组件的值
    //     table_split_visible: {
    //         handler(value) {
    //             // console.log("tableSplit.vue tablie_split_visible:", value);
    //             this.visible = value;
    //         },
    //     },
    //     table_split_data: {
    //         handler(val) {
    //             console.log("tableSplit.vue tablie_split_data:", val);
    //             if(val.min_people){
    //                 this.data = {
    //                     ...this.data,
    //                     num:val.min_people.toString(),
    //                     table_name:val.table_name,
    //                 };
    //             }
    //             this.form = {
    //                 ...this.form,
    //                 table_id:val.id,
    //             };
            
    //         },
    //     },
    //     //test
        
    //     deep: true,
    // },
     watch:{
        visible:{
            handler(){
                if(this.visible){
                    this.$nextTick(() => {
                        this.$refs.input.select();
                    });
                    // window.addEventListener("click", this.changeCount);
                }
            },
            deep:true,
        },
        'data.num':{
            handler(){
                this.blur=false;
               console.log(this.blur)
            },
            deep:true,
        }
    },
    //  beforeDestroy () {
    //     window.removeEventListener("click",this.changeCount); // 取消监听
    // },
    methods: {
        changeCount(){
            this.$nextTick(() => {
                this.$refs.input.select();
            });
        },
        selectText(){
            this.blur=true;
            console.log( this.blur)
        },
        focusText(){
            this.blur=false;
            console.log( this.blur)
        },
        //取消后回传值 给父组件
        handleCancel(e) {
            this.visible = false;
            this.data={
                num: "0",
                table_name:"",
                remark:"",
                elerk:""
            };
            this.form={
                num: "0",
                table_id: 0,
                remark: "",
                elerk:"请选择店员",                
            };
            this.$emit("hiddenFounding", false);
        },

        //确认提交
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                console.log("tableSplit.vue ruleForm:", this.table_split_data);
                if (valid) {                  
                    let data = { 
                        order_clerk_id: this.form.elerk, 
                        people: this.form.num, 
                        remark: this.form.remark, 
                        table_id: this.form.table_id ,
                        foundingLogId: this.form.founding_log_id 
                    };
                    requestTableSplit(data)
                        .then((res) => {
                            // debugger
                            console.log('拆台--------',res);
                            
                            const datas = res.data ? res.data : [];
                            if (datas.status == 200) {
                                console.log(datas);
                                this.$router.push({ path: "/openTable", query: { table_name: datas.data.table_name, foundingLoId: datas.data.founding_log_id } });
                            } else {
                                // this.openNotification('开台失败', datas.message);
                            }
                            this.tableListArr = datas;
                            // console.log(JSON.stringify(this.tableListArr));
                        })
                        .catch((error) => {
                            // alert(123)
                            // console.log(error);
                            
                            this.openNotification('开台请求出错', error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        //获得导购信息
        userCheckList() {
            requestUserClerkList()
                .then((res) => {
                    let rs = res.data;
                    //console.log("table_split.vue userCheckList:", rs);
                    if (rs.status == 200) {
                        this.data.elerk = rs.data.list;
                    } else {
                        this.openNotification('开台信息', res.data.message);
                    }
                })
                .catch((error) => {
                    this.openNotification('开台信息', error);
                });
        },

        //修改人数
        updateNum(num) {
            if(this.key_code){
                this.$set(this.data , this.key_code , num)
                this.$set(this.form , this.key_code , num)
                this.key_num = num
                if(this.key_code == 'num'){
                    this.$refs.ruleForm.validateField('num')
                }
            }
        },

        //提示框
        openNotification(title, content) {
            this.$message.error(title + ": " + content);
        },
        //关闭回调
        autoClose() {},
        //输入框获取焦点
        focushandle(val){
            this.key_num = this.data[val]  + ''
            this.key_num = this.form[val]  + ''
            this.key_code = val
        },
    },
    mounted() {
        this.visible = this.table_split_visible
        if(this.table_split_data.min_people){
            this.data = {
                ...this.data,
                num:this.table_split_data.min_people.toString(),
                table_name:this.table_split_data.table_name,
            };
        }
        this.form = {
            ...this.form,
            table_id:this.table_split_data.id,
        };
        this.userCheckList();
    },
};
</script>

<style lang="less" scoped>
/*改变系统畎认的弹框样式*/
/deep/ .ant-modal-content {
    background-color: #edeef2;
    width:39vw;
    .ant-input-number {
        width: 100%;
        height: 5vh;
        line-height: 5vh;
    }
}

/deep/ .ant-modal-header {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-modal-footer {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-form-item {
    margin-bottom: 1.2vh;
}

/deep/ .ant-form-item-required {
    // font-weight: bold;
}

/deep/ .ant-form-item-label {
    // font-weight: bold;
}

/deep/ .ant-form-item-required::before {
    /*margin-right: 15px;*/
}

/deep/ .ant-btn {
    margin-right: 2vw;
    margin-left: 1vw;
    width: 8vw;
    height: 4.6vh;
    font-size: 1.8vh;
}

/deep/ .ant-btn-primary {
    background: #ec661b;
    border: solid 1px #ec661b;
    margin-left: 2vw;
}

/deep/ .cancel {
    :hover {
        border: solid 1px #ed9c28;
    }
}

/deep/ .primary {
    :hover {
        border: solid 1px #ed9c28;
        background-color: #ed9c28;
    }
}

/deep/ .ant-modal-footer {
    text-align: center;
}

/deep/ .ant-select-selection--single {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-input {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-select-selection__rendered {
    line-height: 3.6vh;
}
/deep/.ant-select-selection__rendered{
    font-size: 1.8vh;
}
</style>
