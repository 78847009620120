<template>
<div class="right">
    <div class="cent_right">
        <div class="ul">
            <ul>
                <li @click="showFounding">点菜(F6)</li>
                <li @click="updateTable">修改</li>
                <!-- <li @click="yujiedan">预结单</li> -->
                <!-- <li @click="settlement">结算</li> -->
                <li @click="kedan">客单</li>
                <li @click="moveMenu">移菜</li>
                <li @click="tableMove">换台</li>
                <li @click="tableMerge">并台</li>
                <li @click="tableSplit">一台多单</li>
                <!-- <li @click="updateTable">账单备注</li> -->
                <li @click="cancelTable">撤台</li>
                <!-- <li @click="showAmount=true">快速收银</li> -->
                
                <!-- <li>

                    <a-dropdown :trigger="['click']">
                        <div class="ant-dropdown-link">
                            其它操作
                            <a-icon type="down" style="margin:1.5vh 0 0 10%; font-size:1.8vh;" />
                        </div>

                        <a-menu slot="overlay" style="width:100%;">
                            <a-menu-item key="0" class="iteMenu" @click="tableSplit">
                                <a-icon type="snippets" /> 一台多单
                            </a-menu-item>
                            <a-menu-divider />

                            <a-menu-item key="8" @click="updateTable">
                                <a-icon type="edit" />账单备注
                            </a-menu-item>
                            <a-menu-divider />

                            <a-menu-item key="3" @click="cancelTable">
                                <a-icon type="close-circle" />撤台
                            </a-menu-item>
                            
                        </a-menu>
                    </a-dropdown>
                </li> -->
            </ul>
        </div>
        <!--  -->
        <div class="cent_box">
            <div class="cent_r">
                <p>{{right_table_status.founding}}</p>
                <p>{{right_table_status.people}}人</p>
            </div>
            <p class="cent_p">
                <span>菜名</span>
                <span>数量</span>
                <span>价格</span>
            </p>
            <div class="cent_c">
                <ul>
                    <li v-for="(item, index) in menuList" :key="index">
                        <p>{{ item.goods_name }}
                            <span v-if="item.is_giving == 2" style="color:#f00;font-style: italic;">(赠)</span>
                        </p>
                        <p>X{{ item.total_num }}</p>
                        <p>{{ item.total_price}}</p>
                    </li>
                </ul>
            </div>
            <div class="cent_b">
                <div class="p">
                    <span>总计</span>
                    <span>{{ numPrice }}</span>
                    <!-- <span>共</span>
                    <span style="color: orange">{{ numData }}</span>
                    <span>件商品</span> -->
                </div>
                <div class="jiesuan" @click="settlement">去结算(F5)</div>
            </div>
        </div>
        <!--  -->
    </div>
    <amountModal :isShow="showAmount" @closeHandle="showAmount=false"/>
</div>
</template>

<script>
import Bus from "@/request/bus";
import amountModal from './quickerCashier/amount.vue'

export default {
    components:{amountModal},
    data() {
        return {
            menuList: [],
            right_table_status:{founding:"未开台", people:0},
            numPrice:'',
            showAmount:false,
        };
    },
    computed: {
        ///总价
        // numPrice: function () {
        //     var price = 0;
        //     for (var i = 0; i < this.menuList.length; i++) {
        //         price += parseFloat(this.menuList[i].total_price);
        //     }
        //     return price.toFixed(2)
        // },
        ////总件
        numData() {
            var num = 0;
            for (var i = 0; i < this.menuList.length; i++) {
                num += this.menuList[i].total_num;
            }
            return num;
        },
    },
    methods: {
        ////点菜进入详情
        showFounding() {
            console.log("rightShowFounding");
            Bus.$emit('rightEvent', "order"); 
        },
        //修改开台数据
        updateTable(){
             Bus.$emit('rightEvent', "updateTable"); 
        },
        //打印客单
        kedan(){
            console.log('kedanEvent');
            Bus.$emit('rightEvent',"kedanPrint");
        },
        //预结单
        yujiedan(){
            Bus.$emit('rightEvent',"yujiedan");
            
        },
        //移菜
        moveMenu(){
            //console.log('moveMenuEvent');
            Bus.$emit('rightEvent',"moveMenu");
        },
        //结算
        settlement(){
            Bus.$emit('rightEvent',"settlement");
        },
        //换台
        tableMove(){
            //console.log('moveMenuEvent');
            Bus.$emit('rightEvent',"tableMove");
        },
        //并台
        tableMerge(){
            console.log('moveMenuEvent');
            Bus.$emit('rightEvent',"tableMerge");
        },
        //一台多单(拆台)
        tableSplit(){
            //console.log('moveMenuEvent');
            Bus.$emit('rightEvent',"tableSplit");
        },
        //补打
        hideModal() {
            console.log(1111);
        },
        // 撤台
        cancelTable(){
            Bus.$emit('rightEvent',"cancelTable");
        },

        //更新当前桌台点菜单
        updateTableOrder() {
            // Bus.$off('right_table_status')
            Bus.$on('right_table_status', right_table_status => {
                this.right_table_status = right_table_status;
            });
            Bus.$off('order_detail')
            Bus.$on('order_detail', (order_detail , data) => {
                console.log('order_detail',order_detail);
                
                this.menuList = order_detail;
                this.numPrice = data.total_price
            });   
              
        },
        quitButton(){
           
        }
        
    },
    mounted() {
        this.updateTableOrder();
    },
};
</script>

<style lang="less" scoped>
.right {
    height: 77vh;
    width: 24vw;
    padding: 0.6vh 0.6vh 0 0.6vh;

    .cent_right {
        height: 100%;
        background: #e4e4e4;
        border-radius: 0.2vw;

        .cent_box {
            box-shadow: 0px 0px 10px 5px rgb(206, 206, 206);

            .cent_r {
                display: flex;
                border-bottom: 1px solid #cdcdcd;
                margin-left: 0.6vw;
                margin-right: 0.4vw;

                p {
                    font-size: 1.8vh;
                    font-weight: bold;
                    margin-top: 1.5vh;
                }

                p:nth-of-type(1) {
                    margin-right: 54%;
                    color: #ff4500;
                }
            }

            .cent_p {
                font-size: 1.8vh;
                margin-bottom: 0.7vh;
                padding-top: 0.5vh;

                span:nth-of-type(1) {
                    display: inline-block;
                    margin-left: 0.6vw;
                     width: 12vw;
                }

                span:nth-of-type(2) {
                    display: inline-block;
                     width: 6vw;
                     padding-left: 0.5vw;
                }
            }

            .cent_c {
                height: 44.1vh;
                font-size: 1vw;
                overflow: auto;
                background: #dddddd;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    font-size: 2vh;

                    li {
                        display: flex;
                        border-bottom: 1px dashed #999;
                        margin-left: 0.6vw;
                        margin-right: 0.2vw;
                        line-height: 4vh;
                        height: 4vh;
                        font-size: 1.4vh;

                        .ant-dropdown-link {
                            width: 40vw
                        }

                        p:nth-of-type(1) {
                            width: 12vw;
                            margin: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        p:nth-of-type(2) {
                            width: 6vw;
                            padding-left: 0.5vw;
                        }
                    }
                }
                &::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
                    height: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    /*滚动条里面小方块*/
                    border-radius: 6px;
                    background   : #AAAAAA;
                }
                &::-webkit-scrollbar-track {
                    /*滚动条里面轨道*/
                    border-radius: 6px;
                    background   : #f3f4f5;
                }
            }

            .cent_b {

                font-size: 1.8vh;
                padding-left: 0.6vw;
                display: flex;
                justify-content: space-between;
                height: 6vh;
                line-height: 6vh;
                .p {
                    span:nth-of-type(2) {
                        margin-left: 0.5vh;
                        color: #ff4500;
                    }
                }
                .jiesuan{
                    background-color: #ff4500;
                    padding: 0 3vh;
                    font-weight: 700;
                    color: #ffffff;
                    border-radius: 0 4px 4px 0;
                    cursor: pointer;
                }
            }
        }

        .ul {
            padding: 0.6vh;
            ul{
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                border-radius: 0.5vh;
                overflow: hidden;
                background: #D6D6D6;
                margin: 0;
                li {
                    width: 7vw;
                    height: 5vh;
                    text-align: center;
                    line-height: 5vh;
                    cursor: pointer;
                    font-size: 1.8vh;
                    border-right: 1px solid #ffffff;
                    border-bottom: 1px solid #ffffff;
                }

                li:hover {
                    background: #f3f4f5;
                }

                li:nth-of-type(3),li:nth-of-type(6),li:nth-of-type(9) {
                    border-right: none;
                }
                li:nth-of-type(7),li:nth-of-type(8),li:nth-of-type(9) {
                    border-bottom: none;
                }
                
                /deep/ .ant-dropdown-link{
                    background-color: #00000000!important;
                }
                // li:nth-of-type(7) {
                //     width: 21vw;
                //     border-bottom:none;
                //     .iteMenu {
                //         margin-bottom: 0.2vh;
                //     }
                // }
            }
            
        }
    }
}

/deep/ .ant-dropdown-link {
    width: 99%;
    
    background-color: #e4e4e4;
    text-align: center;
    height: 4.5vh;
    line-height: 4.55vh;
}

/deep/ .ant-dropdown-menu-item {
    height: 6vh;
    font-size: 1.8vh;
    line-height: 6vh;
}
/deep/ .anticon:first-child{
font-size: 1.8vh;
}
</style>
