<template>
    <div class="win">
        <!-- 存放位置 -->
        <a-modal title="存放位置" :visible="isShow" @cancel="handleCancel" width="40vw">
            <div class="position">
                <div v-for="(item,index) in positionArr"  :key="index"  @click="clickItem(item,index)" :class="[item.is_default==2 ? 'active' : '','item', 'list']">
                    {{item.position_name}}
                    <div class="replace-item" v-if="item.is_default==2">
                        <div class="jiao"></div>
                        <div class="desc">默认</div>
                    </div>
                </div>
                <div v-for="(item,index) in columns"  :key="index+'0'" class="item">
                    <a-input v-model="columns[index]"/>
                </div>
                <div class="item add" @click="add">+添加位置</div>
            </div>
            <template slot="footer">
                <div class="bottomBtn" >
                    <a-button class="clearBtn btn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn btn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { createAccessPosition,getAccessPositionList,positionDefault} from "@/request/business";
export default {
    name:'position',
    props:['isShow'], 
    components:{}, 
    data(){
        return{
            columns:[],
            positionArr:[],
            showInput:true,
        }
    },
    watch:{
        isShow(val){
            if(val){
              this.positionList() 
            }
        }
    },
    methods:{
        handleOk(){
            this.createPosition() 
        },
        createPosition(){
            const parmas=this.columns
            const arr=parmas.filter(item=>{
                if(item){
                    return item
                }
            })
            console.log(JSON.stringify(arr))
            if(parmas.length>0){
                createAccessPosition({position_name:arr}).then(res => {
                if(res.data.status === 200){
                    this.$message.success('添加成功')
                    this.handleCancel()

                }
             })
            }else{
                this.$message.error('请添加位置')
            }
             
        },
        handleCancel(){
            this.columns=[]
            this.$emit('closeHandle')
        },
        add(){
            this.columns.push("")
        },
        positionList(){
            getAccessPositionList().then(res => {
                if(res.data.status === 200){
                    this.positionArr=res.data.data.list && res.data.data.list;
                }
             }) 
        },
        clickItem(item,index){
            positionDefault({position_id:item.position_id}).then(res => {
                if(res.data.status === 200){
                    this.$message.success('设置默认位置成功')
                    this.positionList()
                }
             }) 
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal-content{
    background: #edeef2;
    .ant-modal-header{
       background: #edeef2; 
    }
    .ant-modal-body{
        padding:1.8vh 24px;
        .position{
            .active{
                background: #ff6600;
                color:#fff;
            }
            .item{
                width:100%;
                height:6.2vh;
                text-align: center;
                line-height: 6.2vh;
                border-radius: 4px;
                font-size: 2.2vh;
                margin-top: 2.2vh;
                font-weight: bold;
                 position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .ant-input{
                    height:6.2vh;
                    border-radius: 4px;
                    font-weight: bold;
                    font-size: 2.2vh !important;
                    text-align: center;
                }
                .replace-item {
                    right: 0;
                    top: 0;
                    position: absolute;
                    font-size: 12px;
                    color: #ffffff;
                    .jiao{
                        position: absolute;
                        top:-3.5vh;
                        right: -0.5vw;
                        background: red;
                        width: 2.3vw;
                        height: 10vh;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);;
                        padding:0;
                    }
                    .desc {
                        position: absolute;
                        top:-1.2vh;
                        right: 0vw;
                        transform: rotate(50deg);
                        -webkit-transform: rotate(50deg);
                        -moz-transform: rotate(50deg);
                        -ms-transform: rotate(50deg);
                        font-size: 10px;
                    }
        }   
            }
            .list{
                border: rgba(208, 208, 208, 1) solid 1px;
            }
            .add{
                cursor: pointer;
                border: rgba(208, 208, 208, 1) solid 1px;
                font-weight: bold;
            }
        }
    }
    .ant-modal-footer{
        text-align: center !important;
        .OkBtn {
            margin-left: 4.3vw;
        }
    }
}

</style>