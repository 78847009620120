<template>
  <div class="top">
    <div class="top_left">
      <div class="logo">
        <img :src="oemData.upper_left_img" alt="">
      </div>
      <ul class="yingtab">
        <li :title="item.storey_name"
          v-for="(item, index) in topList"
          :key="index"
          @click="tabBtn(item.storey_name, item.id,index)"
          :class="[categorActive === item.storey_name ? 'selected' : '']"
        >
          {{ item.storey_name }}
        </li>
      </ul>
    </div>
    <div class="top_right">
      <div class="time">{{ nowTime }}</div>
      <!-- <div class="parent"><div class="banci" @click="showShift = true">我的班次</div></div> -->
      <!-- <div class="search">
        <a-input-search placeholder="搜索桌台" @change="onSearch"   />
      </div>
      <div class="jianpan">
        <img :src="require('@/assets/键盘.svg')" alt="" />
      </div>
    </div>
    <Shift :showShift="showShift" v-if="showShift" @hidn="showShift = false"></Shift> -->
    </div>
  </div>
</template>
<script>
import { shopStorey } from "@/request/api";
import { compare } from "@/public/common";
import Bus from "@/request/bus";
// import Shift from "./shift/shift"
import { mapState } from "vuex";

export default {
  data() {
    return {
      topList: [],
      categorActive: "全部桌台",
      //当前区域选中状态
      categoryStatus:0,
      // showShift:false
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
    };
  },
  computed: {
    ...mapState(['oemData']),
  },
  components:{
    // Shift
  },
  methods: {
    //餐桌列表
    tabList() {
      shopStorey({
        // page: 1,
        is_use: 1,
        // shop_id: this.shop_id,
      })
        .then((res) => {
          const topListold = res.data.data.list ? res.data.data.list : [];
          const topListNew = [];
          //console.log(topListold);

          topListNew.push({ storey_name: "全部桌台", sort: 0, id: 0 });
          topListNew.push({ storey_name: "已开桌台", sort: 0, id: 1 });
          topListNew.push({ storey_name: "未开桌台", sort: 0, id: 2 });
          for (let i = 0; i < topListold.length; i++) {
            topListNew.push({
              storey_name: topListold[i].storey_name ? topListold[i].storey_name : "",
              sort: topListold[i].sort ? topListold[i].sort : "",
              id: topListold[i].id ? topListold[i].id : "",
            });
          }
          this.topList = topListNew.sort(compare("sort"));
          //console.log(this.topList);
        })
        .catch((error) => {
          return false;
        });
    },
    // 按区域查餐桌
    tabBtn(storey_name,id,index) {
      // debugger
      let noChange = this.categoryStatus == id
      let type = index > 2 ? 'storey_id' : 'is_founding'
      this.categoryStatus = id;
      this.categorActive = storey_name;
      localStorage.setItem( 'tableCateType' , type)
      localStorage.setItem( 'tableCate' , this.categoryStatus)
      localStorage.setItem( 'categorActive' , this.categorActive)

      Bus.$emit('categoryStatus', this.categoryStatus , noChange,type); 
      
    },
    //搜索
    // onSearch(val){
    //     console.log(val);
    // },
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second ;
      // console.log(this.nowTime)
    },
  },
  mounted() {
    let categorActive = localStorage.getItem('categorActive')
    let tableCate = localStorage.getItem('tableCate')
    if(categorActive && tableCate){
      this.categorActive = categorActive
      this.categoryStatus = tableCate
    }
    this.tabList();
    this.timer = setInterval(this.getTime, 1000);
  },
  beforeDestroy() {    //页面关闭时清除定时器  
    clearInterval(this.timer);
  },

};
</script>
<style lang="less" scoped>
.top {

  display: flex;
  background: #333333;
  line-height: 4vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  justify-content: space-between;
  align-items: center;
  .top_left {
    display: flex;
    align-items: center;
    background: #333333;
    line-height: 4vh;
    width: 77vw;
    .logo{
      width:2.5vw;
      height:2.5vw;
      margin-left: 2vw;
      background-color: #ff6700;
      margin-right:1.3vw;
      border-radius: 5px;
       padding: 0.2vh;
      display: flex;
      justify-content:center;
      align-items: center;
      img{
        // height: 100%;
        width: 100%;
        padding-right: 2px;
      }
    }
    .yingtab {
      display: flex;
      padding: 0;
      margin: 0;
      flex: 1;
      overflow-x: auto;
      &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
          height: 3px;
      }
      &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 3px;
          background   : #999999;
      }
      &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          border-radius: 3px;
          background   : #cccccc;
      }
      li {
        width: 8vw;
        min-width: 108px;
        line-height: 4vh;
        height: 4vh;
        margin: 0.5vh 0.5vw 0.5vh 0.1vw;
        border-radius: 0.2vw;
        font-size: 2vh;
        list-style: none;
        font-weight: bold;
        background: #dddddd;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
      }
      .selected {
        background-color: coral;
      }
      li:hover {
        background: #595758;
        color: white;
      }
    }
  }
  .top_right {
    // width: 23vw;
    margin-right: 1vh;
    display: flex;
    align-items: center;
    .time {
      width: 10vw;
      height: 4vh;
      line-height: 4vh;
      // margin: 0.8vh 0 0.8vh 1.5vw;
      // padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 2vh;
      color: #e4e4e4;
      background-color:#595758;
    }
    // .parent {
    //   .banci {
    //     display: block;
    //     width: 8vw;
    //     min-width: 102px;
    //     line-height: 4vh;
    //     height: 4vh;
    //     font-size: 2vh;
    //     margin: 0.5vh 0.3vw 0.5vh 0.1vw;
    //     padding: 0 1vw 0 1vw;
    //     border-radius: 0.2vw;
    //     list-style: none;
    //     font-weight: bold;
    //     background: #dddddd;
    //     text-align: center;
    //     cursor: pointer;
    //   }

    //   :hover {
    //     background: #595758;
    //     color: white;
    //     text-align: center;
    //   }
    // }

    .search {
      display: flex;
      width: 10vw;
      height: 4vh;
      line-height: 4vh;
      
      margin: 0.5vh 0vw 0vw 0.6vw;
      /deep/ .ant-input{
      font-size: 2vh;
      height: 4vh;
      border: 0;
      border-radius: 0.4vh 0 0 0.4vh;
      border-right: 1px solid #dddddd;
      width: 10vw;
      }
    }
    .jianpan {
      width: 4vw;
      display: flex;
      height: 4vh;
      margin: 0.5vh 0 0 0;
      margin: 0.5vh 0 0 -1vw;
    background-color: #fff;
    border-radius: 0.4vh;
    cursor: pointer;
      img {
        width: 3.6vw;
        padding-left: 1vw;
      }
    }
    
  }
}
/deep/ .ant-input-suffix {
    font-size: 1.8vh;
}
</style>
