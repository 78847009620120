<template>
<div class="window">
    <!-- 移菜弹框 -->
    <a-modal v-model="visible" :title="this.default.title" @cancel="handleCancel" :destroyOnClose="true" width="66vw">
        <template slot="footer">
            <span class="cancel">
                <a-button key="back" @click="handleCancel"> {{this.default.cancel}} </a-button>
            </span>
            <span class="primary"  v-if="order_detail && order_detail.length">
                <a-button key="button" type="primary" @click="handleOk">
                    {{ this.default.next}}
                </a-button>
            </span>
        </template>
        <a-form-model ref="ruleForm" v-if="order_detail && order_detail.length">
            <div class="avoid" v-if="this.default.selectTableVisible">
                <a-radio-group v-model="form.receive_table_id">
                    <a-radio v-for="(item ,index) in tableListArr" :key="index" :value="item.id" :disabled="item.id == form.give_table_id ? true:false">
                        {{item.table_name}}
                    </a-radio>
                </a-radio-group>
                
                <div :style="'width:60vw;height:3.6vh;' + (tableListArr.length % 5 == 0 ? '' : 'margin: -4vh 0 0 0vw;')" v-if="tableListArr && tableListArr.length && tableListArr.length > 34">
                    <div style="margin-left:82.5%; width:23%;">
                        <div class="page" @click="upPage">上一页</div>
                        <div class="page" @click="nextPage">下一页</div>
                    </div>
                </div>
            </div>
            <!-- 第二步 -->
            <div class="avoid" v-if="this.default.selectTableVisible ? false:true">
                <a-checkbox-group v-model="form.order_goods_id">
                    <a-checkbox v-for="(item ,index) in order_detail" :key="index" :value="item.order_goods_id">
                        {{item.goods_name}}
                    </a-checkbox>
                </a-checkbox-group>
            </div>
        </a-form-model>
        <div v-else style="text-align:center;color:#f00;">当前桌台暂无可移动菜品</div>
    </a-modal>
    <!-- 移菜弹框 -->
</div>
</template>

<script>
import { tableList } from "@/request/api";
import Bus from "@/request/bus";
import { requestMoveMenu } from "@/request/founding";

export default {
    data() {
        return {
            tableListArr: [],
            visible: false,
            default: {
                next: "下一步",
                cancel: "取消",
                status: 1, // 1:选择桌台, 2: 提交移菜
                title: '',
                selectTableVisible: true,
            },
            form: {
                //被移动桌台id
                give_table_id: 0,
                //接收桌台id
                receive_table_id: 0,
                //要移动的菜
                order_goods_id: [],

            },
            page: {
                //区域id
                storey_id: 0,
                //0: 全部 1:己开台 2:未开台
                is_founding: 1,
                //餐桌编码
                table_coding: '',
                //速查码
                qiuck_coding: '',
                //桌台名称
                table_name: '',
                //区域名称
                storey_name: '',
                //当前页
                page: 1,
                //最后一页
                last_page: 0,
            },

        };
    },
    components: {

    },
    props: {
        move_menu_visible: {},
        move_menu_data: {},
        order_detail:{
            type:Array,
            default:[]
        }
    },
    // watch: {
    //     move_menu_visible: {
    //         handler(val) {
    //             this.visible = val;
    //         }
    //     },
    //     move_menu_data: {
    //         handler(val) {
    //             // console.log(val);
    //             this.form.give_table_id = val.id;
    //             this.default = {
    //                 ...this.default,
    //                 title: "请选择要把菜品移动到的桌台【当前桌台: " + val.table_name + "】",
    //             }

    //         }
    //     }
    // },
    //  watch:{
    //     visible:{
    //         handler(){
    //             if(this.visible){
    //                 const _this=this;
    //                 document.onkeydown = function(e) {
    //                     let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
    //                     if (e1 && e1.keyCode == 13) { 
    //                     _this.handleOk()
    //                     } 
    //                 }    
    //             }
    //         },
    //         deep:true,
    //     }
    // },
    methods: {
        //取消后回传值 给父组件
        handleCancel(e) {
            this.visible = false;
            this.form = {
                ...this.form,
                receive_table_id :0,
                give_table_id:0,
                order_goods_id:[],
            };
            this.$emit("hiddenFounding", false);
            this.default = {
                ...this.default,
                next: "下一步",
                status: 1,
                selectTableVisible: true,
            };
            //重置本弹出窗口
            this.default = {
                ...this.default,
                next: "下一步",
                cancel: "取消",
                status: 1, // 1:选择桌台, 2: 提交移菜
                title: '',
                selectTableVisible: true,
            };
        },
        checkBoxClick(val) {
            this.form.receive_table_id = val.id;
            console.log(val.id);
        },

        //确认提交
        handleOk() {

            if (this.default.status == 1) {                
                if (this.form.receive_table_id == 0) {
                    this.openNotification('操作提示', '请选择接收桌台')
                    return;
                }
                this.default = {
                    ...this.default,
                    next: "提交",
                    status: 2,
                    selectTableVisible: false,
                };
                return;
            } else {
                let param = {
                    give_table_id:this.form.give_table_id, 
                    receive_table_id:this.form.receive_table_id, 
                    order_goods_id:this.form.order_goods_id,
                    };
                requestMoveMenu(param)
                    .then((res) => {
                        const datas = res.data ? res.data : [];
                        if (datas.status == 200) {
                            //成功后处理父级数据
                            this.$emit("hiddenFounding", false);
                            //重置本弹出窗口
                            this.default = {
                                ...this.default,
                                next: "下一步",
                                cancel: "取消",
                                status: 1, // 1:选择桌台, 2: 提交移菜
                                title: '',
                                selectTableVisible: true,
                            };
                            let founding_log_id = ''
                            this.tableListArr.forEach(item => {
                                if(item.id == this.form.receive_table_id){
                                    founding_log_id = item.founding_log_id
                                }
                            })
                            let json ={key:"zhuancaiAction", value:{founding_log_id}} 
                            this.socketApi.websocketsend(json);
                            this.$message.success('移菜成功')
                        } else {
                            this.openNotification('移菜失败', datas.message);
                        }
                        //请求完成, 初始化数据
                    })
                    .catch((error) => {
                        this.openNotification('请求出错', error);
                    });

            }

        },

        //重置各项
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },

        //全部餐桌列表请求
        tableListRequest() {
            const _this = this;
            //console.log(this.page);
            tableList(_this.page)
                .then((res) => {
                    let data = res.data.data.list.data ? res.data.data.list.data : [];
                    this.tableListArr = data;
                    this.page.last_page = res.data.data.list.last_page;
                })
                .catch((error) => {
                    return false;
                });
        },
        //下一页
        nextPage() {
            if (this.page.page >= this.page.last_page) {
                this.openNotification('请求提示', '已经是最后一页了')
                return;
            }
            this.page.page += 1;
            this.tableListRequest();
        },
        //上一页
        upPage() {
            if (this.page.page <= 1) {
                this.openNotification('请求提示', '已经在第一页了');
                return;
            }
            this.page.page -= 1;
            this.tableListRequest();
        },
        //提示框
        openNotification(title, content) {
            this.$message.error(title + ": " + content);
        },
    },
    mounted() {
        this.visible = this.move_menu_visible;
        this.form.give_table_id = this.move_menu_data.id;
        this.default = {
            ...this.default,
            title: "请选择要把菜品移动到的桌台【当前桌台: " + this.move_menu_data.table_name + "】",
        }
        this.tableListRequest();
    },
};
</script>

<style lang="less" scoped>
.page {
    font-size: 1.6vh;
    background-color: #444;
    width: 40%;
    height: 3.5vh;

    line-height: 3.5vh;
    border-radius: 4px;
    border: 1px solid rgba(208, 208, 208, 1);
    margin: 0.6vh 0vh 0 0vw;
    display: inline-block;
    cursor: pointer;
    color: #d44027;
    text-align: center;
}

/*改变系统畎认的弹框样式*/
/deep/.ant-radio-group{
    width: 100%;
    height: 100%;
}
/deep/.ant-radio-wrapper {
    font-size: 1.6vh;
    background-color: white;
    width: 17.7%;
    height: 3.5vh;
    padding-left: 0.5vw;
    line-height: 3.5vh;
    border-radius: 4px;
    border: 1px solid rgba(208, 208, 208, 1);
    margin-top: 0.6vh;
    margin-left: 0.6vw;
}

/deep/.ant-radio+span {
    padding-left: 0.1vw;
    padding-top: 0.2vh;
}

/deep/.ant-radio-wrapper-checked {
    background-color: rgba(255, 102, 0, 1);
    color: white;
}

/deep/.ant-radio-checked .ant-radio-inner {
    border-color: white;
    background-color: rgba(255, 102, 0, 1);
}

/deep/ .ant-checkbox-group {
    width: 100%;
}

/deep/.ant-checkbox-wrapper {
    font-size: 1.6vh;
    background-color: white;
    width: 31.7%;
    height: 3.5vh;
    padding-left: 0.5vw;
    line-height: 3.5vh;
    border-radius: 4px;
    border: 1px solid rgba(208, 208, 208, 1);
    margin-top: 0.6vh;
    margin-left: 0.6vw;
}

/deep/.ant-checkbox+span {
    padding-left: 0.1vw;
    padding-top: 0.2vh;
}

/deep/.ant-checkbox+span {
    padding-left: 0.1vw;
    padding-top: 0.2vh;
}

/deep/.ant-checkbox-wrapper-checked {
    background-color: rgba(255, 102, 0, 1);
    color: white;
}

/deep/.ant-checkbox-checked .ant-checkbox-inner {
    border-color: white;
    background-color: rgba(255, 102, 0, 1);
}

/deep/ .ant-modal-content {
    background-color: #edeef2;

    .ant-input-number {
        width: 100%;
        height: 5vh;
        line-height: 5vh;
    }
}

/deep/ .ant-modal-header {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-modal-footer {
    background-color: #edeef2;
    border-bottom: solid 1px rgba(229, 229, 229, 1);
}

/deep/ .ant-form-item {
    margin-bottom: 1.2vh;
}

/deep/ .ant-form-item-required {
    font-weight: bold;
}

/deep/ .ant-form-item-label {
    font-weight: bold;
}

/deep/ .ant-form-item-required::before {
    /*margin-right: 15px;*/
}

/deep/ .ant-btn {
    margin-right: 2vw;
    margin-left: 1vw;
    width: 8vw;
    height: 4.6vh;
    font-size: 1.8vh;
}

/deep/ .ant-btn-primary {
    background: #ec661b;
    border: solid 1px #ec661b;
    margin-left: 2vw;
}

/deep/ .cancel {
    :hover {
        border: solid 1px #ed9c28;
    }
}

/deep/ .primary {
    :hover {
        border: solid 1px #ed9c28;
        background-color: #ed9c28;
    }
}

/deep/ .ant-modal-footer {
    text-align: center;
}

/deep/ .ant-select-selection--single {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-input {
    width: 100%;
    height: 3.6vh;
    line-height: 3.6vh;
}

/deep/ .ant-select-selection__rendered {
    line-height: 3.6vh;
}
</style>
